<template>
  <div v-if="childInfoMap && childInfoMap.childHeadline && childInfoMap.childHeadline.length > 0">
    <div style="padding: 0 30px" v-for="item in childInfoMap.childHeadline"
         :key="item && item.headlineEntity && item.headlineEntity.id">
      <h3 class="title">{{ item.headlineEntity.titleName }}</h3>
      <p class="state noPaddingTop"
         v-if="item.headlineEntity.id === '1430781544934408194' && item && item.content && item.content.type == 1"
         v-html="item.content.content">
      </p>
      <!--    左图右文本-->
      <div class="contenBox" v-if="item && item.content && item.content.type == 2">
        <div class="imgBox"><img :src="getImgUrl(item.content.imgUrl)"/></div>
        <div v-html="item && item.content && item.content.content" class="htmlBox"></div>
      </div>
      <!--    左文本右图-->
      <div class="contenBox" v-if="item && item.content && item.content.type == 3">
        <div v-html="item && item.content && item.content.content" class="htmlBox"></div>
        <div class="imgBox"><img :src="getImgUrl(item.content.imgUrl)"/></div>
      </div>
      <!--    <h3 class="title">合作伙伴</h3>-->
      <ul class="friend" v-else-if="item.headlineEntity.id === '1430781544934408195'">
        <li v-for="itemImg in item.contentImg" :key="itemImg.id">
          <img :src="getImgUrl(itemImg.pictureUrl)" alt=""/>
        </li>
      </ul>
      <div v-else></div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getImgUrl} from '@/api/index.js';

  export default {
    props: {
      'childInfoMap': Object,
    },
    data() {
      return {
      };
    },
    created() {
    },
    methods: {
      getImgUrl(url) {
        return getImgUrl(url)
      },
    },
  };
</script>

<style lang="less" scoped>
  #app {
    text-align: left;
  }

  .title {
    border-left: 4px solid #ee020d;
    height: 26px;
    line-height: 26px;
    padding-left: 10px;
    font-size: 22px;
    margin: 40px 0;
    font-weight: normal;
  }

  .state {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    text-indent: 30px;
    color: #666;
  }
  .noPaddingTop {
    padding-top: 0!important;
  }

  .contenBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      padding: 10px;
    }

    .imgBox {
      width: 30%
    }
  ;

    .htmlBox {
      width: 70%
    }
  ;
  }

  .friend {
    width: 82%;
    margin: 0 auto;

    li {
      float: left;
      border: 1px solid #ccc;
      margin: 15px;
      list-style-type: none;

      img {
        width: 153px;
        height: 52px;
      }
    }
  }
</style>
