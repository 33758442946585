<template>
    <h3 class="title">{{childInfoMap && childInfoMap.contents}}</h3>
</template>

<script>
  export default {
    name: "title.vue",
    props: {
      'childInfoMap': Object,
    },
  }
</script>

<style  lang="less" scoped>
  .title {
    border-left: 4px solid #ee020d;
    height: 26px;
    line-height: 26px;
    padding-left: 10px;
    font-size: 22px;
    margin: 40px 0;
    font-weight: normal;
    margin-bottom: 0;
  }
</style>
