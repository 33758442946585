<template>
  <div class="container">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <ul class="navbar">
        <li v-for="item in childHeadline" :key="item.headlineEntity.id">
         <router-link :to="item.headlineEntity.path"><a href="javascript:void(0)">{{ item.headlineEntity.titleName }}</a></router-link>
        </li>
      </ul>
      <div class="content" v-if="contentMap.type == 3">
        <div class="fl" v-html="contentMap.content">
        </div>
        <div class="fr">
          <img :src="getImgUrl(contentMap.imgUrl)" alt=""/>
        </div>
      </div>
      <div class="content" v-if="contentMap.type == 2">
        <div class="boxTwo">
          <div style="width: 45%;">
            <img :src="getImgUrl(contentMap.imgUrl)" alt=""/>
          </div>
          <div style="width: 54%;" v-html="contentMap.content"></div>
        </div>
      </div>
      <div class="content" v-if="contentMap.type == 1">
        <div class="fl" style="width: 98%;" v-html="contentMap.content">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GaterJYK_list, getImgUrl } from '@/api/index.js';
export default {
  data() {
    return {
      childHeadline: [], // 各模块数据集合
      titlePicture: "", // 标题图片
      contentMap: {},
      titleName: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
     
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      GaterJYK_list(data).then(res=> {
        if(res.success){
          this.childHeadline = res.data.childHeadline;
          this.contentMap = res.data.content,
          this.titlePicture = getImgUrl(res.data.headlineImg.picture);
        }
      })
      .catch(error=>{
          console.log(error)
      });
    },
    getImgUrl(url) {
      return getImgUrl(url)
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .doc {
    padding: 0 0 50px 30px;
    .navbar {
      background: url(http://www.juyike.cn/Public/Home/images/jyknavBg.jpg)
        no-repeat 0 bottom;
      width: 80%;
      margin: 30px auto;
      list-style-type: none;
      li {
        width: 25%;
        height: 115px;
        float: left;
        text-align: center;
        cursor: pointer;
        a {
          color: #ee020d;
          font-size: 20px;
          height: 115px;
          line-height: 115px;
          display: inline-block;
          width: 100%;
          text-decoration: none;
        }
        a:hover {
          background: url(http://www.juyike.cn/Public/Home/images/hover.jpg)
            no-repeat;
          color: #fff;
          background-size: 100% 115px;
        }
      }
    }
    .navbar:after {
      content: "\20";
      display: block;
      clear: both;
      height: 0;
      overflow: hidden;
    }
    .content {
      margin-top: 100px;
      position: static;
      width: 100%;
      .fl {
        float: left;
        width: 40%;
        text-align: left;
        .tit {
          font-size: 14px;
          color: #666;
          line-height: 30px;
          margin: 0 0 0 10%;
        }
        .side {
          font-size: 16px;
          line-height: 40px;
          margin: 5% 0 0 20%;
        }
      }
      .fr {
        float: right;
        width: 45%;
        margin-right: 5%;
        img {
          max-width: 573px;
          width: 100%;
        }
      }
      .boxTwo{
        display: flex;
        flex-direction: row;
        margin-top: 40px;
      }
    }
  }
}
</style>
