<template>
  <div class="container">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <div class="relative">
        <h3 class="title">{{ content.headlineEntity.titleName}}</h3>
        <div class="clearfix" v-if="content.headlineEntity.id === '1431102500051615744'">
          <a :href="itemOne.link" target="_blank" class="video" v-for="(itemOne, oneIndex) in content.content" :key="itemOne.id" :class="showAdiv" >
            <span></span>
            <img :src="getImgUrl(itemOne.picture)"/><br />
            {{ itemOne.titleName}}
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { getImgUrl } from '@/api/index.js';
  export default {
    data() {
      return {
        titlePicture: "", // 标题图片
        content: {},
        titleName:'',
      }
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        const staffInfo = JSON.parse(sessionStorage.getItem('staffInfo'));
        this.titleName = staffInfo.titleName;
        this.titlePicture = staffInfo.titlePicture;
        this.content = staffInfo.content;
      },
      getImgUrl(url) {
        return getImgUrl(url)
      },
    },
  }
</script>


<style lang="less" scoped>
  .container {
    width: 78.5%;
    left: 12.5%;
    position: absolute;
    top: 0;
    min-width: 1050px;
    max-width: 1200px;
    box-shadow: 5px 0 5px #f0f0f0;
    font-family: "微软雅黑";
    .banner {
      width: 100%;
      position: relative;
      h2 {
        font-weight: 500;
        color: #fff;
        opacity: 0.7;
        font-size: 42px;
        line-height: 42px;
        position: absolute;
        left: 25px;
        top: 40%;
      }
      img {
        width: 100%;
        height: 230px;
      }
    }
    .doc {
      padding: 0 0 50px 30px;
      .title {
        border-left: 4px solid #ee020d;
        height: 26px;
        line-height: 26px;
        padding-left: 10px;
        font-size: 22px;
        margin: 40px 0;
      }
      .relative {
        position: relative;
        .more {
          position: absolute;
          right: 60px;
          top: 0px;
          color: #999;
          font-size: 16px;
          cursor: pointer;
        }
        .more:hover {
          color: #aa001b;
        }
        .clearfix {
          display: flow-root;
          .showAdiv{
            display: block;
          }
          .hideAdiv{
            display: none;
          }
          .video {
            width: 40%;
            margin-left: 6%;
            margin-bottom: 4%;
            float: left;
            text-align: center;
            font-size: 16px;
            position: relative;
            height: 295px;
            text-decoration: none;
            outline: none;
            color: #333;
            span {
              background: url(http://www.juyike.cn/Public/Home/images/mediaBg.png)
              no-repeat;
              width: 100%;
              position: absolute;
              z-index: 6;
              left: 0;
              top: 0;
              height: 100%;
              background-size: 100%;
            }
            img {
              max-width: 472px;
              margin-bottom: 10px;
              height: 89%;
              width: 100%;
            }
          }
          .video:hover {
            color: #aa001b;
          }
        }
      }
      .ygfcscroll{
        position:relative;
        padding:40px 0px 20px;
        color:#999;
        margin-left:-30px;
      }
      .ygfcscroll-box{width:940px;margin:0 auto 50px;overflow:hidden;}
      .ygfcscroll-prev,.ygfcscroll-next{
        position:absolute;
        top:42%;
        width:32px;
        height:32px;
        background:url(../../assets/point02.png) no-repeat 0 0;
        opacity: .5
      }
      .ygfcscroll-prev{left:2%;background-position: 0 0;}
      .ygfcscroll-next{right:2%;background-position: -35px 0;}
      .ygfcscroll-prev:hover,.ygfcscroll-next:hover{opacity:9}
      .ygfcscroll-box ul{width:9999px;position:relative;}
      .ygfcscroll-box li{
        float:left;
        width:2.25%;
        border:1px solid #e5e5e5;
        margin-right:10px;
        position: relative;
        list-style-type: none;
      }
      .ygfcscroll-box li a{color:#666;}
      #ygfcscroll-btn{display:none;}
    }
  }
</style>
