<template>
  <div v-if="childInfoMap && childInfoMap.childHeadline && childInfoMap.childHeadline.length > 0">
    <div style="padding: 0 30px"  v-for="(item, index) in childInfoMap.childHeadline" :key="item && item.headlineEntity && item.headlineEntity.id" >
      <h3 class="title">{{ item.headlineEntity.titleName }}</h3>
      <p class="state noPaddingTop" v-if="index == 0 && item && item.content && item.content.type == 1" v-html="item && item.content && item.content.content"></p>
      <!--    左图右文本-->
      <div class="contenBox" v-if="item && item.content && item.content.type == 2">
        <div class="imgBox"><img :src="getImgUrl(item.content.imgUrl)" /></div>
        <div  v-html="item && item.content && item.content.content"  class="htmlBox"></div>
      </div>
      <!--    左文本右图-->
      <div class="contenBox" v-if="item && item.content && item.content.type == 3">
        <div  v-html="item && item.content && item.content.content" class="htmlBox"></div>
        <div class="imgBox"><img :src="getImgUrl(item.content.imgUrl)" /></div>
      </div>
      <SwiperView :imgList="item.contentImg"></SwiperView>
    </div>
  </div>
</template>

<script>
  import {  getImgUrl } from '@/api/index.js';
  import SwiperView from '../../../components/swiperView.vue'
export default {
  components:{
            SwiperView,
  },
  props: {
    'childInfoMap': Object,
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
    btnClick(flag, list){
      if(list && list.length >= 4){
        let ulDiv = document.getElementById('clearfix');
        console.log( ulDiv.getElementsByTagName('li'),'=== ulDiv.getElementsByTagName(\'li\')')
        let liOne = ulDiv.getElementsByTagName('li')[0];
        let liFive = ulDiv.getElementsByTagName('li')[list.length -1];

        if(flag == 'left') {
          ulDiv.childNodes[1].before(liFive);
        } else {
          ulDiv.childNodes[list.length +1].before(liOne);
        }
      }
    },
    getImgUrl(url) {
      return getImgUrl(url)
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  text-align: left;
}
.title {
  border-left: 4px solid #ee020d;
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  font-size: 22px;
  margin: 40px 0;
  font-weight: normal;
}
.state {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  text-indent: 30px;
  color: #666;
  margin-bottom: 50px;
}
.noPaddingTop {
  padding-top: 0!important;
}
.contenBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  div{
    padding: 10px;
  }
  .imgBox{width: 30%};
  .htmlBox{width: 70%};
}
.friend {
  width: 80%;
  margin: 0 auto;
  li {
    float: left;
    border: 1px solid #ccc;
    margin: 15px;
    list-style-type: none;
    img {
      width: 153px;
      height: 52px;
    }
  }
}
.ygfcscroll{
  position:relative;
  padding:40px 0px 20px;
  color:#999;
  margin-left:-30px;
}
.ygfcscroll-box{width:940px;margin:0 auto 50px;overflow:hidden;}
.ygfcscroll-prev,.ygfcscroll-next{
  position:absolute;
  top:42%;
  width:32px;
  height:32px;
  background:url(../../../assets/point02.png) no-repeat 0 0;
  opacity: .5
}
.ygfcscroll-prev{left:2%;background-position: 0 0;}
.ygfcscroll-next{right:2%;background-position: -35px 0;}
.ygfcscroll-prev:hover,.ygfcscroll-next:hover{opacity:9}
.ygfcscroll-box ul{width:9999px;position:relative;}
.ygfcscroll-box li{
  float:left;
  width:2.25%;
  border:1px solid #e5e5e5;
  margin-right:10px;
  position: relative;
  list-style-type: none;
}
.ygfcscroll-box li a{color:#666;}
#ygfcscroll-btn{display:none;}
</style>
