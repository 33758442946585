<template>
  <div class="container">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName}}</h2>
    </div>
    <div class="doc" v-for="(item,index) in childHeadline" :key="item.headlineEntity.id">
      <div class="corecon" v-if="index === 0 && item.content.type == 3">
      <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
      <div class="fl"  v-if="index ===0" v-html="item && item.content && item.content.content"></div>
      <div class="fr">
        <img :src="getImgUrl(item.content.imgUrl)" alt=""/>
      </div>
    </div>
      <div class="corecon" v-else-if="index === 0 && item.content.type == 2">
        <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
        <div class="boxTwo">
          <div style="width: 45%;">
            <img :src="getImgUrl(item.content.imgUrl)" alt=""/>
          </div>
          <div  v-if="index ===0" style="width: 54%;" v-html="item && item.content && item.content.content"></div>
        </div>
      </div>
      <div class="corecon" v-else-if="index === 0 && item.content.type == 1">
        <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
        <div class="fl" style="width: 98%;"  v-if="index ===0" v-html="item && item.content && item.content.content"></div>
      </div>
      <div class="enterhonor"  v-else-if="index === 1">
        <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
        <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true" lazy="true">
          <el-tab-pane :label="itemCont.companyName"  v-for="(itemCont, indexs) in item.content" :key="itemCont.id" :name="indexs">
          </el-tab-pane>
        </el-tabs>
        <!-- v-for="itemCont in item.content" :key="itemCont.id" -->
         <!-- <span :id="itemCont.id">{{ itemCont.companyName}}</span> -->
        <div>
          <ul
                  class="Timeline"
                  v-for="itemThird in item.content[activeName].cultures"
                  :key="itemThird.dateNum"
          >
            <li>
              <h4>{{ itemThird.dateNum }}</h4>
              <div class="pBox" v-for="(itemThirdss) in itemThird.cultureList" :key="itemThirdss.id" v-html="itemThirdss.content"></div>
            </li>
          </ul>
          <SwiperView v-if="item.content[activeName].culturesPic && item.content[activeName].culturesPic.length >0" :imgList="item.content[activeName].culturesPic" :key="item.content[activeName].id" class="swiperBox"></SwiperView>
        </div>
      </div>
      <!-- <ul class="rcjsNav fixBox" id="float_ad">
        <li v-for="item in hrefList" :key="item.id"  @click="liClickFnc(item.id)"><a href="javascript:;" class="first">{{item.name}}</a></li>
      </ul> -->
    </div>
  </div>
</template>
<script>
import { EnterpriseCulture_list, getImgUrl } from '@/api/index.js';
import SwiperView from '../../components/swiperView.vue'
export default {
  components:{
    SwiperView,
  },
  data() {
    return {
      enterpricedata:[],
      titlePicture: '',
      childHeadline: {},
      titleName: '',
        hrefList: [],
      activeName: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
      liClickFnc(flag){ // l滚动
          window.scroll({
              top: document.getElementById(flag).offsetTop - 50,
              behavior: "smooth"
          })
      },
     
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      EnterpriseCulture_list(data).then(res=> {
        if(res.success){
          this.childHeadline = res.data.childHeadline;
          const data = this.childHeadline.find(item => item.headlineEntity && item.headlineEntity.id === '1430737230799831040')
            if (data) {
                console.log(data.content)
                this.hrefList = data.content.map(item => {
                    return {
                        id: item.id,
                        name: item.companyName,
                    }
                });
                console.log(this.hrefList)
            }
          this.titlePicture = getImgUrl(res.data.headlineImg.picture);
        }
      })
      .catch(error=>{
          console.log(error)
      });
    },
    getImgUrl(url) {
      return getImgUrl(url)
    },
  },
};
</script>
<!-- scoped -->
<style lang="less" >
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: auto;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .doc {
    padding: 0 0 0 30px;
    .title {
      border-left: 4px solid #ee020d;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      font-size: 22px;
      margin: 40px 0;
      font-weight: normal;
      margin-bottom: 0;
    }
    .corecon {
      display: inline-block;
      .fl {
        float: left;
        width: 45%;
        .side {
          font-size: 18px;
          line-height: 30px;
          margin: 4% 0 0 5%;
          span {
            background: url(http://www.juyike.cn/Public/Home/images/i.png)
              no-repeat 0 center;
            padding-left: 20px;
            color: #000;
          }
        }
        .tit {
          font-size: 16px;
          color: #666;
          line-height: 30px;
          margin: 4% 0 0 5%;
          text-indent: 33px;
        }
      }
      .fr {
        float: right;
        width: 45%;
        margin-right: 5%;
        img {
          max-width: 573px;
        }
      }
      .boxTwo{
        display: flex;
        flex-direction: row;
        margin-top: 40px;
      }
    }
    .enterhonor {
      .el-tabs__nav {
        height: 56px;
        padding: 20px 0;
        .el-tabs__item {
          height: 100%;
          line-height: 56px;
          font-size: 25px;
          border-right: 2px solid #ccc;
        }
        .el-tabs__item:nth-last-of-type(1) {
          border-right: none;
        }
        .el-tabs__item:hover {
          color: #a90621;
        }
        .el-tabs__item.is-active {
          color: #a90621;
        }
        .el-tabs__active-bar {
          background-color: #a90621;
        }
      }
      .el-tabs__nav-wrap::after {
        content: none;
      }
      span {
        text-align: center;
        width: 100%;
        display: inline-block;
        padding: 0 0 20px 0;
        font-size: 20px;
      }
      .state {
        margin-left: 13%;
        line-height: 30px;
        width: 74%;
        margin: 0 auto;
        font-size: 16px;
        text-indent: 30px;
        color: #666;
      }
      .title{
        margin-bottom: 20px;
      }
    }
    // 时间轴
    .Timeline {
      border-left: 2px solid #fa504b;
      position: relative;
      margin-left: 32px;
      z-index: 15;
      top: 20px;
      li {
        width: 90%;
        top: -20px;
        position: relative;
        left: -32px;
        z-index: 20;
        list-style-type: none;
        padding-top: 0!important;
        h4 {
          background: url(http://www.juyike.cn/Public/Home/images/year.png)
          no-repeat;
          width: 66px;
          height: 66px;
          color: #fa504b;
          font-size: 20px;
          text-align: center;
          line-height: 66px;
        }
        .pBox{
          margin: 20px 0 20px 65px;
          position: relative;
          line-height: 30px;
        }
        .pBox:nth-of-type(1):before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #fa504b;
          position: absolute;
          left: -38px;
          top: 10px;
        }
      }
    }
    .swiperBox{
      margin-top: 50px;
    }
  }
  .fixBox{
    position:fixed;
    top: 350px;
  }
  .rcjsNav{
    /*margin-left: 71%;*/
    right: 10%;
    border-left: 1px solid #e2e2e2;
    padding-top: 10px;
    li {
      list-style-type: none;
      margin-bottom: 50px;
      padding-left: 10px;
      position: relative;
      font-size: 12px;
      cursor: pointer;
      a {
        text-decoration: none;
        outline: none;
        color: #333;
        cursor: pointer;
      }
      a:hover{color:#ee020d;}
    }
    li:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #e2e2e2;
      border-radius: 50%;
      display: inline-block;
      margin-left: -16px;
      margin-right: 5px;
    }
    li:hover:before{background:#ee020d;}
  }
}
</style>
