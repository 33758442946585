<template>
  <div style="padding: 0 30px">
    <div class="text" v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 1">
    </div>
<!--    左图右文本-->
    <div class="contenBox" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 2">
      <div class="imgBox"><img :src="getImgUrl(childInfoMap.content.imgUrl)" /></div>
      <div  v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content" class="htmlBox"></div>
    </div>
    <!--    左文本右图-->
    <div class="contenBox" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 3">
      <div  v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content" class="htmlBox"></div>
      <div class="imgBox"><img :src="getImgUrl(childInfoMap.content.imgUrl)" /></div>
    </div>
    <!-- 企业成员 -->
    <div class="enterprise-member" v-for="item in childInfoMap.childHeadline" :key="item.headlineEntity.id">
      <h3 class="title">{{ item && item.headlineEntity && item.headlineEntity.titleName }}</h3>
      <p class="picList" v-if="item.childHeadline && item.childHeadline.length >0">
        <a
          href="javascript:;"
          v-for="(itemOne, index) in item.childHeadline"
          :key="itemOne.headlineEntity.id"
          :class="isactive == index ? 'cur' : ''"
          @click="changeImg(index, itemOne.headlineEntity.id)"
          :style="{
            background: eval(itemOne && itemOne.headlineImg && itemOne.headlineImg.picture ? getImgUrl(itemOne.headlineImg.picture) : ''),
          }"
        ></a>
      </p>
    </div>
    <div class="viewcontent" id="viewcontent">
        <!-- 联储证券isShow &&  dataMap.content.headlineId == '1430781544934408198' || dataMap.content.headlineId == '1430781544934408199'-->
          <SwiperView  v-if="dataMap && dataMap.contentImg && dataMap.contentImg.length > 0 && (dataMap.content.headlineId == '1430781544934408198' || dataMap.content.headlineId == '1430781544934408199')" :imgList="dataMap.contentImg" :key="dataMap.content.headlineId" :ids="dataMap.content.headlineId"></SwiperView>
          <!-- 富国大通  -->
          <!-- <SwiperView  v-else-if="changeFlag && dataMap && dataMap.contentImg && dataMap.contentImg.length > 0 && dataMap.content.headlineId == '1430781544934408199'" :imgList="dataMap.contentImg" :key="1430781544934408199" :ids="1430781544934408199"></SwiperView> -->
          <div class="list-img" v-else>
              <div v-if="dataMap && dataMap.contentImg && dataMap.contentImg.length > 0">
              <img
                          alt=""
                          v-for="itemImg in dataMap.contentImg"
                          :key="itemImg.id"
                          :src="getImgUrl(itemImg.pictureUrl)"
                          :class="dataMap.content.headlineId === '1430781544934408200' ? 'imgwidth' : ''"
                  />
                  </div>
          </div>
          <!-- </div> -->
          <div class="introduce">
            <div v-html="dataMap && dataMap.content && dataMap.content.content"></div>
            <p class="ewm" v-show="dataMap && dataMap.qrCodeList && dataMap.qrCodeList.length > 0">
              <span v-for="itemQrCode in dataMap.qrCodeList" :key="itemQrCode.id">
                {{ itemQrCode.orName }}
                <img :src="itemQrCode && itemQrCode.picture ? getImgUrl(itemQrCode.picture) : ''" v-if="itemQrCode && itemQrCode.picture" alt="">
              </span>
            </p>
          </div>
    </div>
  </div>
</template>

<script>
  import { info_childSonInfo_list, getImgUrl } from '@/api/index.js';
  import SwiperView from '../../../components/swiperView.vue'
export default {
  components:{
            SwiperView,
  },
  props: {
    'childInfoMap': Object,
    'dataMapInfo': Object,
  },
  data() {
    return {
      dataMap: {
        content: {
          content: '<div></div>',
        },
        contentImg: [],
        qrCodeList: [{orName: '', picture: ''}]
      },
        // newImgList: [],
      isactive: 0,
      list: null,
      clickFlag: false,
      changeFlag: true,
      // isShow: true,
      ListContent: [
        {
          imgContent: [
            { name: "http://www.juyike.cn/Public/Home/images/img001.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img002.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img003.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img004.jpg" },
          ],
          introduceTitel: "联储证券",
          introduceText: [
            { text: '联储证券有限责任公司（简称“联储证券”）是一家以“创造价值”为使命的综合性券商，致力于成为财富管理行业的改变者和推动者。联储证券专注于中国高净值人群和优质企业，致力于为客户提供高效、便捷、专业的投融资服务。公司秉承多元化的发展理念，为企业、个人、金融机构、政府客户和机构投资者提供一站式的业务服务。' },
            { text: '联储证券原名众成证券，成立于2001年2月28日，注册资本13.731亿元，注册地为深圳。2016年2月22日,“众成证券有限责任公司”' },
          ],
          ewm: [
            {
              name: '联储证券官网二维码：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/clzqewm01.jpg'
            },
            {
              name: '微信订阅号二维码：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/clzqewm02.jpg'
            },
            {
              name: '新浪微博二维码：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/clzqewm02.jpg'
            },
          ],
        },
        {
          imgContent: [
            { name: "http://www.juyike.cn/Public/Home/images/img005.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img006.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img007.jpg" },
            { name: "http://www.juyike.cn/Public/Home/images/img008.jpg" },
          ],
          introduceTitel: "富国大通",
          introduceText: [
            { text: '北京富国大通资产管理有限公司成立于2014年，注册资金1亿元，为高净值人群提供公募基金、契约型基金、资管产品等优质资产配置。' },
            { text: '伴随中国资本市场的迅速发展，富国大通以前瞻性视野布局财富管理行业，将合规风控置于战略高度，帮助客户进行' },
          ],
          ewm: [
            {
              name: '富国大通订阅号：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/fgdtewm01.jpg'
            },
            {
              name: '富国大通官网：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/fgdtewm01.jpg'
            },
          ],
        },
        {
          imgContent: [
            { name: "http://www.juyike.cn/Public/Home/images/img011.jpg", width: 'imgwidth' },
            { name: "http://www.juyike.cn/Public/Home/images/img012.jpg", width: 'imgwidth' },
          ],
          introduceTitel: "北信瑞丰",
          introduceText: [
            { text: '北信瑞丰基金管理有限公司成立于2014年3月17日，是经中国证监会证监许可[2014]265号文批准，由北京国际信托有限公司与莱州瑞海投资有限公司两家股东共同发起设立的基金公司。公司结合基金行业特点改善公司治理结构，积极推进股权激励，并在专户业务及子公司各业务条线进行事业部制改革。北信瑞丰基金着力打造具有高水准的投研团队，公司高管和主要投资管理人员的金融相关从业年限均在10年以上，拥有丰富的管理经验和证券投资实战经验。' },
            { text: '截至2018年4月30日，公司共有17只公募产品，保有139只专户产品，资产管理规模超过420亿元，其中公募资产管理规模为57.68亿元（2014年8月成立第一只公募产品，2014年底达14亿，2015年底达34亿）。公司于2015年7月成立子公司，截至2018年4月30日，母子公司整体资管规模超过610亿元。' },
          ],
          ewm: [
            {
              name: '官方微信二维码：',
              imgewm: '	http://www.juyike.cn/Public/Home/images/bxrfewm01.jpg'
            },
            {
              name: '北信瑞丰微财富二维码：',
              imgewm: 'http://www.juyike.cn/Public/Home/images/bxrfewm02.jpg'
            },
          ],
        },
      ],
    };
  },
  created() {
    this.list = this.ListContent[0];
    // console.log("==111==");
    // this.isShow = false;
  },
  mounted() {
    this.dataMap = this.dataMapInfo;
  },
  watch:{
    dataMapInfo: function (newVal){
      this.dataMap = newVal;
    },
    dataMap: function(){
      this.changeFlag = false;
      this.$nextTick(()=>{
        this.changeFlag = true;
      })
    }
  },
  methods: {
    changeImg(index, id) {
      this.isactive = index;
      this.list = this.ListContent[index];
      const data = {id}
      // if(id == '1430781544934408200'){
      //   this.changeFlag = false;
      // }
      info_childSonInfo_list(data).then(res=> {
        if(res.success){
          this.dataMap = res.data;  
          // this.changeFlag = true;
        }
      })
      .catch(error=>{
        console.log(error)
      });
    },
      eval(url) {
          return 'url(' + url + ')'
      },
    getImgUrl(url) {
      return getImgUrl(url)
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  text-align: left;
}
.text {
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  color: #333;
  font-size: 18px;
  color: #333;
  font: 16px/1.7 Tahoma, "Hiragino Sans GB", "Microsoft YaHei", arial, simsun,
    sans-serif;
  text-indent: 30px;
}
.contenBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div{
    padding: 10px;
  }
  .imgBox{width: 30%};
  .htmlBox{width: 70%};
}
.enterprise-member {
  /*padding-bottom: 45px;*/
  height: 201px;
  .title {
    border-left: 4px solid #ee020d;
    height: 26px;
    line-height: 26px;
    padding-left: 10px;
    font-size: 22px;
    margin: 40px 0;
    font-weight: normal;
  }
  .picList {
    margin: 0 auto;
    width: 1000px;
    text-align: center;
    display: flow-root;
    a {
      width: 25%;
      cursor: pointer;
      max-width: 233px;
      display: inline-block;
      height: 88px;
      border: 1px solid #ccc;
      float: left;
      margin: 0 40px;
      img {
        background-size: 100% auto;
      }
    }
    .cur {
      border: 2px solid red;
      position: relative;
      height: 86px;
    }
    .cur:after {
      content: "";
      background: url(http://www.juyike.cn/Public/Home/images/act.png) no-repeat;
      width: 16px;
      height: 14px;
      position: absolute;
      left: 45.5%;
      bottom: -14px;
      display: block;
    }
  }
  .list-img {
    width: 96%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    img {
      width: 24.89%;
      max-width: 276px;
      margin-left: 1px;
    }
  }
}
.viewcontent {
  clear: both;
}
.imgwidth {
  width: 49.8% !important;
  max-width: 551px !important;
  height: 176px;
}
imgSmallWidth{
    width:225px;
    height:198px;
}
.ygfcscroll{
    position:relative;
    /*padding:20px 0px 20px;*/
    color:#999;
    margin-left:-30px;
}
.ygfcscroll-box{width:940px;margin:0 auto 50px;overflow:hidden;}
.ygfcscroll-prev,.ygfcscroll-next{
    position:absolute;
    top:42%;
    width:32px;
    height:32px;
    background:url(../../../assets/point02.png) no-repeat 0 0;
    opacity: .5
}
.ygfcscroll-prev{left:2%;background-position: 0 0;}
.ygfcscroll-next{right:2%;background-position: -35px 0;}
.ygfcscroll-prev:hover,.ygfcscroll-next:hover{opacity:9}
.ygfcscroll-box ul{width:9999px;position:relative;}
.ygfcscroll-box li{
    float:left;
    width:225px;
    /*border:1px solid #e5e5e5;*/
    margin-right:10px;
    position: relative;
    list-style-type: none;
}
.ygfcscroll-box li a{color:#666;}
#ygfcscroll-btn{display:none;}
.introduce {
  margin-top: 20px;
  h2 {
    font-weight: normal;
  }
  .introduce-text {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    text-indent: 30px;
    color: #666;
    font: 16px/1.7 Tahoma,"Hiragino Sans GB","Microsoft YaHei",arial,simsun,sans-serif;
  }
  .ewm {
    width: 96%;
    margin-left: 2%;
    text-align: center;
    span {
      width: 178px;
      text-align: center;
      margin: 5%;
      display: inline-block;
      img {
        width: 158px;
        display: block;
        margin-left: 10px;
            border: none;
    vertical-align: middle;
      }
    }
  }
}

    /*@-webkit-keyframes mymove {*/
    /*    100% {right: 235px;}*/
    /*}*/
    @keyframes mymove1 {
        from {left: -200px;}
        to {left: 200px;}
    }

@keyframes mymove2 {
    from {right: -200px;}
    to {right: 200px;}
}
    /*#clearfix li{*/
    /*    !*-webkit-animation: mymove 3s infinite;*!*/
    /*    animation: mymove 3s infinite;*/
    /*    animation-timing-function: linear;*/
    /*}*/

.mymove1{
  display: none;
    /*-webkit-animation: mymove 3s infinite;*/
    animation: mymove1 1s infinite;
    animation-timing-function: linear;
}

.mymove2{
  display: none;
    /*-webkit-animation: mymove 3s infinite;*/
    animation: mymove2 1s infinite;
    animation-timing-function: linear;
}
  .anmitionLf{

    -webkit-transition: left .8s;
    -moz-transition: left .8s;
    -o-transition: left .8s;
    transition: left .8s;
  }
.anmitionRi{

  -webkit-transition: margin-right .8s;
  -moz-transition: margin-right .8s;
  -o-transition: margin-right .8s;
  transition: margin-right .8s;
}
  .anmitionBox{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    .leftImg{ width: 8%;
      position: relative;}
    .leftImg span{
      position:absolute;
      top:42%;
      left: 8%;
      width:32px;
      height:32px;
      background:url(../../../assets/point02.png) no-repeat 0 0;
      opacity: .5
    }
    .rightImg{ width: 8%; position: relative;}
    .rightImg span{
      position:absolute;
      top:42%;
      width:32px;
      height:32px;
      background:url(../../../assets/point02.png) no-repeat 0 0;
      opacity: .5;
      right:8%;
      background-position: -35px 0;
    }
    .centerImg{
      width: 940px;
      overflow: hidden;
      .ulBox{ width: 9000px;  height: 198px; display: inline-block;position: relative;}
      .liBox{ display: inline-block; width: 225px; margin-right: 10px;position: relative;}
    }
  }
  .mn-swiper{
    width: 1000px !important;
  }

</style>
