<template>
    <div class="container">
        <div class="banner">
            <img
                :src="result.img"
                v-if="result.img"
                :alt="result.titleName"
            />
            <h2>{{ result.titleName }}</h2>
        </div>
        <div class="docBottomBox">
            <div v-for="item in result.compentList" :key="item.id">
                <div v-if="item.type === 'briefIntroduction'" :id="item.id"><ContentView :childInfoMap="item" ></ContentView></div>
                <div v-else-if="item.type === 'title'" :id="item.id"><TitleView :childInfoMap="item"></TitleView></div>
                <div v-else-if="item.type === 'transverseList' || item.type === 'portraitList'" :id="item.id"><ListView :childInfoMap="item"></ListView></div>
                <div v-else-if="item.type === 'anchorPoint'" :id="item.type"><AnchorPointView :childInfoMap="item" :liClickFnc="liClickFnc"></AnchorPointView></div>
            </div>
        </div>
    </div>

</template>

<script>
    import { getImgUrl, getTemplateData } from '@/api/index.js';
    import ContentView from './components/content'
    import ListView from './components/list'
    import TitleView from './components/title'
    import AnchorPointView from './components/anchorPoint'
    export default {
        name: "index.vue",
        components: {
            ContentView,
            ListView,
            TitleView,
            AnchorPointView,
        },
        data() {
            return {
                result: {
                    picture: getImgUrl(null),
                    titleName: '',
                    compentList: [],
                }
            }
        },
        created() {
            this.getData();
            this.scrollFnc();
        },
        watch: {
            $route: {
                handler() {
                    this.getData();
                    //深度监听，同时也可监听到param参数变化
                },
                deep: true,
            }
        },
        methods: {
            // 获取数据
            getData() {
                const param = {}
                if (this.$route.path.indexOf('/template') > -1) {
                    param.id = this.$route.params.id;
                }

                getTemplateData(param).then(res=> {
                    if(res.success){
                        this.result = res.data;
                        this.result.img = getImgUrl(res.data.img)
                    }
                })
                .catch(error=>{
                    console.log(error)
                });
            },
          scrollFnc(){
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            let bodyHeight = document.getElementsByTagName("body")[0].scrollTop;
            let htmlHeight = document.getElementsByTagName("html")[0].scrollTop;
            if(bodyHeight >= 300 || htmlHeight>= 300){
              document.getElementsByClassName("rcjsNav")[0].className = 'rcjsNav fixBox';
            }
          },
          liClickFnc(id){ // l滚动

            window.scroll({
              top: document.getElementById(id).offsetTop - 30,
              behavior: "smooth"
            })
          },
        },

    }
</script>

<style lang="less" scoped>
    .container {
        width: 78.5%;
        left: 12.5%;
        position: absolute;
        top: 0;
        min-width: 1050px;
        max-width: 1200px;
        box-shadow: 5px 0 5px #f0f0f0;
        font-family: "微软雅黑";

        .banner {
            width: 100%;
            position: relative;
            min-height: 202px;

            img {
                width: 100%;
                height: 230px;
            }

            h2 {
                font-weight: 500;
                color: #fff;
                opacity: 0.7;
                font-size: 42px;
                line-height: 42px;
                position: absolute;
                left: 25px;
                top: 40%;
            }
        }

        .docBottomBox {
            padding: 0 0 50px 30px;
            width: 88%;
        }
    }
    @media screen and (min-width: 1500px) {
      .container .rcjsNav  {
        right: 2.5rem!important;
      }
    }
</style>
