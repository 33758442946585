<template>
  <ul class="rcjsNav fixBox" >
    <li v-for="liItem in childInfoMap.contents" id="fixBoxLi" :key="liItem.id" @click="liClickFnc(liItem.id)"><a href="javascript:;" @click="liClickFnc(liItem.id)">{{ liItem.content }}</a></li>
  </ul>
</template>

<script>
  export default {
    name: "anchorPoint.vue",
    props: {
      childInfoMap: Object,
      liClickFnc: Function,
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>
  .fixBox{
    position:fixed;
    top: 350px;
  }
  .rcjsNav{
    right: 1.1rem;
    border-left: 1px solid #e2e2e2;
    padding-top: 0.1rem;
    li {
      list-style-type: none;
      margin-bottom: 50px;
      padding-left: 10px;
      position: relative;
      font-size: 12px;
      cursor: pointer;
      a {
        text-decoration: none;
        outline: none;
        color: #333;
        cursor: pointer;
      }
      a:hover{color:#ee020d;}
    }
    li:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #e2e2e2;
      border-radius: 50%;
      display: inline-block;
      margin-left: -16px;
      margin-right: 5px;
      pointer-event:auto;
    }
    li:hover:before{background:#ee020d;pointer-event:auto;}
  }
</style>
