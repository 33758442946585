<template>
  <div class="container">
    <div class="banner">
      <img :src="imgUrl" v-if="imgUrl" :alt="bannerName" />
      <h2>{{ bannerName }}</h2>
    </div>
    <div class="doc">
      <div class="research">
        <el-input v-model="titleName" placeholder="关键字" @change="searchData()"></el-input>
        <el-button @click="searchData()">搜索</el-button>
      </div>
      <!-- .slice((currentPage - 1) * pagesize, currentPage * pagesize) -->
      <el-table :data="
          tableData
        " style="width: 100%"
        :show-header="false"
        empty-text="暂无数据"
        >
        <el-table-column width="100%"> 
          <template #default="{ row }">
            <span>
              <router-link :to="{name:'followDetail', params: {id:row.id}}" @click="routeLinkFnc(row.id)">
                <a href="#">
                  <e>[{{ row.companyName }}]</e>
                </a>
                <a href="#">{{ row.title }}</a>
              </router-link>
            </span>
            <span>{{row.releaseTime ? dateFormat(row.releaseTime) : null}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="pagShow">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next" :background="true" :total="tableDataLength" ref="pagination" >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import { ConcernJyklist_list, ConcernJykImg, getImgUrl, dateFormat } from '@/api/index.js';
  export default {
    data() {
      return {
        titleName: "",
        currentPage: 1, //初始页
        pagesize: 10, //    每页的数据
        tableData: [],
        tableDataLength: '',
        imgUrl: '', // 标题imgurl
        bannerName: '',
        pagShow: true,
      };
    },
    created() {
      this.currentPage = sessionStorage.getItem("currentPage") ? parseInt(sessionStorage.getItem("currentPage")) : 1;
      this.getData()
    },
    methods: {
      searchData() {
        this.getData(1)
      },
      dateFormat(time) {
        return dateFormat(time).split(' ')[0]
      },
      routeLinkFnc(id) {
        sessionStorage.setItem("followId", id);
        sessionStorage.setItem("currentPage",  this.currentPage);
      },
      // 获取数据
      getData(currentPage) {
        this.pagShow = false;
        let menuList = JSON.parse(sessionStorage.getItem("menuList"));
        menuList.map(item => {
          if (item.children) {
            menuList = menuList.concat(item.children)
          }
        })
        let item = menuList.filter(item => item.path === this.$route.path);
        this.bannerName = item[item.length - 1].titleName;
        const data = { data: { id: item[item.length - 1].id, title: this.titleName }, page: currentPage ? currentPage : this.currentPage, row: this.pagesize }
        ConcernJyklist_list(data).then(res => {
          this.pagShow = true;
          this.tableData = res.data.list;
          this.tableDataLength = parseInt(res.data.total);
          sessionStorage.removeItem("currentPage");
        })
          .catch(error => {
            console.log(error)
          });
        const dataMap = { id: item[item.length - 1].id }
        ConcernJykImg(dataMap).then(res => {
          if (res.success) {
            this.imgUrl = getImgUrl(res.data.picture);
          }
        })
          .catch(error => {
            console.log(error)
          });
      },
      handleSizeChange(val) {
        this.pagesize = val;
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getData(val)
      }
    },
  };
</script>
<style lang="less">
  .container {
    width: 78.5%;
    left: 12.5%;
    position: absolute;
    top: 0;
    min-width: 1050px;
    max-width: 1200px;
    box-shadow: 5px 0 5px #f0f0f0;
    font-family: "微软雅黑";

    .banner {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        height: 230px;
      }

      h2 {
        font-weight: 500;
        color: #fff;
        opacity: 0.7;
        font-size: 42px;
        line-height: 42px;
        position: absolute;
        left: 25px;
        top: 40%;
      }
    }

    .doc {
      .research {
        margin: 4% 0 0 4%;

        .el-input {
          display: inline;

          .el-input__inner {
            border: 1px solid #a90621;
            width: 200px;
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            float: left;
            border-radius: 0;
          }
        }

        .el-button {
          background: #a90621;
          text-align: center;
          min-height: 30px;
          color: #fff;
          padding: 0 8px;
          float: left;
          border-radius: 0;
          border: 1px solid #a90621;
        }
      }
      .el-table__empty-text{
        width: 100% !important;
      }
      .el-table__body-wrapper {
        width: 92%;
        margin: 20px auto 30px;

        .el-table__body {
          width: 100% !important;

          span:nth-of-type(1) {
            float: left;
            display: inline-block;
            width: 85%;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            padding: 10px 0;

            a {
              text-decoration: none;
              color: #333;
            }

            a:hover {
              color: #aa001b;
            }

            e:hover {
              color: #aa001b;
            }

            a:nth-of-type(1) {
              color: #666;
              margin-right: 3px;
            }

          }

          span:nth-of-type(2) {
            padding: 10px 0;
            float: right;
            color: #737373;
            display: inline-block;
            /*width: 5.85%;*/
            white-space: nowrap;
          }
        }
      }

      /*.el-pagination {*/
      /*  text-align: center;*/
      /*}*/
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #a90621;
        border-color: #ccc;
      }
      .el-pager {
        min-width: auto!important;
        width: auto!important;
        margin: 0!important;
        .number {
          width: auto !important;
          padding: 0 4px !important;
        }
        li {
          width: auto !important;
          padding: 0 4px !important;
        }
      }
      /deep/ .el-pager {
        min-width: auto!important;
        width: auto!important;
        margin: 0!important;
        li {
          width: auto !important;
          padding: 0 4px !important;
        }
        number {
          width: auto !important;
          padding: 0 4px !important;
        }
      }
      .el-pagination {
        text-align: center;
        font-size: 0;
        color: #666;
        margin-top: 40px;
      }
    }
  }
</style>
