<template>
    <div class="containerBox">

        <!--    纯文本-->
        <div class="listText" v-html="childInfoMap && childInfoMap.contents && childInfoMap.contents.content"
             v-if="childInfoMap && childInfoMap.contents && childInfoMap.contents.type == 1">
        </div>
        <!--    纯文本-->
        <div class="listText contentCenter" v-html="childInfoMap && childInfoMap.contents && childInfoMap.contents.content"
             v-if="childInfoMap && childInfoMap.contents && childInfoMap.contents.type == 4">
        </div>
        <!--    左图右文本-->
        <div class="listContenBox" v-if="childInfoMap && childInfoMap.contents && childInfoMap.contents.type == 2">
            <div class="imgBox left"><img :src="getImgUrl(childInfoMap.contents.imgUrl)"/></div>
            <div v-html="childInfoMap && childInfoMap.contents && childInfoMap.contents.content" class="htmlBox"></div>
        </div>
        <!--    左文本右图-->
        <div class="listContenBox" v-if="childInfoMap && childInfoMap.contents && childInfoMap.contents.type == 3">
            <div v-html="childInfoMap && childInfoMap.contents && childInfoMap.contents.content" class="htmlBox left"></div>
            <div class="imgBox"><img :src="getImgUrl(childInfoMap.contents.imgUrl)"/></div>
        </div>
    </div>

</template>

<script>
import { getImgUrl } from '@/api/index.js';
export default {
    props: {
        'childInfoMap': Object,
    },
    data() {
        return {
        }
    },
    methods: {
        getImgUrl(url) {
            return getImgUrl(url)
        },
    },
}
</script>

<style lang="less" scoped>
.containerBox {

    .listText {
        font-size: 16px;
        color: #333;
        font: 16px/1.7 Tahoma, "Hiragino Sans GB", "Microsoft YaHei", arial, simsun,
        sans-serif;
        word-break: break-all;
        padding: 30px 0 30px 1%;
    }
    .contentCenter {
        width: 90%;
        margin: 0 auto;
        margin-right: 2%;
    }
    .listContenBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 0 30px 1%;
        /*width: 90%;*/
        .imgBox {
            width: 40%;
        }
        .left {
            margin-right: 2%;
        }

        .htmlBox {
            width: 58%;
            margin-top: 3%;
            word-break: break-all;
            font-size: 16px;
        }
    }
}
</style>
