<template>
  <div class="container">
    <div class="banner">
      <img
              :src="titlePicture"
              v-if="titlePicture"
              :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <div class="gjleft">

        <!-- 企业概况 -->
        <!--        <div>-->
        <!--          <h3 class="title">企业概况</h3>-->
        <!--          &lt;!&ndash; <h3 class="title">{{enterpricedata.titles[0].titleName}}</h3> &ndash;&gt;-->
        <!--          <p class="intro" v-html="enterpricedata.companyProfile.content"></p>-->
        <!--        </div>-->
        <!-- 发展历程 -->

        <div v-for="(item,index) in childHeadline" :key="item.headlineEntity.id">
          <div v-if="item.content.type == 3 && index === 0">
              <span class="imgGoin">
                <img :src="getImgUrl(item.content.imgUrl)" style="width: 270px;"
                     alt="财富管理，款爷投资，风险投资"
                />
              </span>
            <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
            <p class="intro" v-html="item && item.content && item.content.content"></p>
          </div>
          <div v-else-if="item.content.type == 1 && index === 0">
            <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
            <p class="intro" v-html="item && item.content && item.content.content" style="width: 95%"></p>
          </div>
          <div v-else-if="item.content.type == 2 && index === 0">
            <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
            <div class="leftImgBox">
              <div class="imgGoindiv">
                <img :src="getImgUrl(item.content.imgUrl)"
                     alt="财富管理，款爷投资，风险投资"
                />
              </div>
              <div class="introDiv" v-html="item && item.content && item.content.content" style="width: 95%"></div>
            </div>
          </div>
          <div v-else-if="index === 1">
            <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
            <ul
                    class="Timeline"
                    v-for="item in item.content"
                    :key="item.dateNum"
            >
              <li>
                <h4>{{ item.dateNum }}</h4>
                <p v-for="(items, indexs) in item.childList" :key="items.id" v-html="items.content"></p>
              </li>
            </ul>
          </div>
          <div v-else>null</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {selectAllByYear_list, getImgUrl} from '@/api/index.js';

  export default {
    data() {
      return {
        enterpricedata: [],
        // 企业概况
        listEnterpriseverview: [],
        // 发展历程
        listDevelopmentHistory: [],
        // 右侧图片
        imagesdata: [],
        titlePicture: '',
        childHeadline: [],
        titleName: '',
      };
    },
    created() {
      this.getData();

    },
    methods: {

      // 获取数据
      getData() {
        let menuList = JSON.parse(sessionStorage.getItem("menuList"));
        menuList.map(item => {
          if (item.children) {
            menuList = menuList.concat(item.children)
          }
        })
        let item = menuList.filter(item => item.path === this.$route.path);
        this.titleName = item[item.length - 1].titleName;
        const data = {id: item[item.length - 1].id};
        selectAllByYear_list(data).then(res => {
          if (res.success) {
            this.childHeadline = res.data.childHeadline;
            this.titlePicture = getImgUrl(res.data.headlineImg.picture);
          }
        })
            .catch(error => {
              console.log(error)
            });
      },
      getImgUrl(url) {
        return getImgUrl(url)
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    width: 78.5%;
    left: 12.5%;
    position: absolute;
    top: 0;
    min-width: 1050px;
    max-width: 1200px;
    box-shadow: 5px 0 5px #f0f0f0;
    font-family: "微软雅黑";

    .banner {
      width: 100%;
      position: relative;

      img {
        width: 100%;
      }

      h2 {
        font-weight: 500;
        color: #fff;
        opacity: 0.7;
        font-size: 42px;
        line-height: 42px;
        position: absolute;
        left: 25px;
        top: 40%;
      }
    }

    .doc {
      padding: 0 0 50px 30px;

      .gjleft {
        width: 100%;
        position: relative;
        text-align: left;

        .imgGoin {
          width: 35%;
          position: absolute;
          top: 1.3%;
          right: 10%;
          max-width: 505px;

          img {
            width: 100%;
          }
        }

        .title {
          border-left: 4px solid #ee020d;
          height: 26px;
          line-height: 26px;
          padding-left: 10px;
          font-size: 22px;
          margin: 40px 0;
          font-weight: normal;
        }

        .intro {
          text-indent: 33px;
          font-size: 16px;
          margin-top: 30px;
          margin-bottom: 30px;
          width: 50%;
          letter-spacing: 2px;
          margin-left: 18px;
          font: 16px/1.7 Tahoma, "Hiragino Sans GB", "Microsoft YaHei", arial,
          simsun, sans-serif;
        }

        .leftImgBox {
          display: flex;
          flex-direction: row;

          .introDiv {
            padding: 0 30px 0 10px;
          }
        }

        // 时间轴
        .Timeline {
          border-left: 2px solid #fa504b;
          position: relative;
          margin-left: 32px;
          z-index: 15;
          top: 20px;

          li {
            width: 90%;
            top: -20px;
            padding-top: 0;
            position: relative;
            left: -32px;
            z-index: 20;
            list-style-type: none;

            h4 {
              background: url(http://www.juyike.cn/Public/Home/images/year.png) no-repeat;
              width: 66px;
              height: 66px;
              color: #fa504b;
              font-size: 20px;
              text-align: center;
              line-height: 66px;
            }

            p {
              margin: 20px 0 20px 65px;
              position: relative;
              line-height: 30px;
            }

            p:nth-of-type(1):before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #fa504b;
              position: absolute;
              left: -38px;
              top: 10px;
            }
          }
        }
      }
    }
  }
</style>
