<template>
  <div class="container">
    <div class="banner">
      <img
              :src="data.headlineImg.picture"
              v-if="data.headlineImg.picture"
              alt="关注聚益科"
      />
      <h2>关注聚益科</h2>
    </div>
    <div class="doc">
      <div class="detail relative">
        <h3>{{info.title}}</h3>
        <span class="time">{{info.updateTime ? dateFormat(info.updateTime) : null}}</span>
        <a class="back" @click="prev">返回</a>
        <!-- history.go(-1) -->
        <div class="contentS" v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Colourful_ContentInfo_list, getImgUrl, dateFormat} from '@/api/index.js';

  export default {
    data() {
      return {
        data: {},
        info: {},
      };
    },
    created() {
      this.id = this.$route.query.id;
      const data = sessionStorage.getItem('data')
      this.data = JSON.parse(data);
      this.getData(this.$route.query.id)
    },
    methods: {
      prev(){
        // history.go(-1)
        this.$router.push({ path: "/details", query: { id: this.info.headlineId } });
      },
      getImgUrl(url) {
        return getImgUrl(url)
      },
      dateFormat(time) {
        return dateFormat(time)
      },
      getData(id) {
        const data = {id: id}
        Colourful_ContentInfo_list(data).then(res => {
          if (res.data) {
            this.info = res.data;
          }
        }).catch(error => {
          console.log(error)
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    width: 78.5%;
    left: 12.5%;
    position: absolute;
    top: 0;
    min-width: 1050px;
    max-width: 1200px;
    box-shadow: 5px 0 5px #f0f0f0;
    font-family: "微软雅黑";

    /deep/ img {
      width: auto !important;
    }

    .banner {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        height: 230px;
      }

      h2 {
        font-weight: 500;
        color: #fff;
        opacity: 0.7;
        font-size: 42px;
        line-height: 42px;
        position: absolute;
        left: 25px;
        top: 40%;
      }
    }

    .detail {
      margin: 45px auto;
      width: 90%;
      font-size: 16px;

      h3 {
        text-align: center;
        font-size: 20px;
        border-left: 0 !important;
      }

      .time {
        margin: 20px 0 40px;
        text-align: center;
        width: 100%;
        display: inline-block;
      }

      .content {

      }

      .back {
        width: 106px;
        height: 35px;
        background: #eee;
        border: 1px solid #dcdcdc;
        text-align: center;
        line-height: 35px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        color: #333;
      }
    }

    .doc {
      padding: 0 0 50px 30px;
    }

    .relative {
      position: relative;
    }
  }
</style>
