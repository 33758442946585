<template>
    <div class="swiperContentBox">
        <div class="mn-wrap">
            <div class="swiper-container mn-swiper" :class="[{'otherDivWidth': ids == '1430781544934408198' || ids == '1430781544934408199'}]">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="itemTwo in imgList" :key="itemTwo.id" :class="[{'otherWidth': ids == '1430781544934408198' | ids == '1430781544934408199'}]"
                         ref="swiperSlide">
                        <img :src="itemTwo.pictureUrl ? getImgUrl(itemTwo.pictureUrl) : getImgUrl(itemTwo.content)" class="example-image">
                    </div>
                </div>

            </div>
            <div id="iShow" :class="[{'noShow': ids == '9999'}]">
                <div id="lightboxOverlay" class="lightboxOverlay" style="width: 1903px; height: 2279px; display: none;" @click="closeBox()">
                    <div class="imgDiv">
                        <span class="closeIcon" @click="closeBox()"></span>
                        <img class="imgBox" id="imgBox" src="http://testattachment.fuguowealth.com/test_article_1438417308375580672.jpg">
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-prev arrow-left"></div>
        <div class="swiper-button-next arrow-right"></div>
    </div>

</template>
<script>
    import '../assets/css/swiper.min.css';
    import '../assets/css/index.css';
    import Swiper from '../assets/js/swiper.min.js';
    import { getImgUrl, } from '@/api/index.js';
    var mySwiper = {};
    var imgUrL = '';
    // var dialogTableVisible = false;
    $(function () {
        let that = this;
        //轮播图
        mySwiper = new Swiper('.mn-swiper', {
            autoplay: false,
            touchRatio: 1,
            speed: 1000,  //匀速时间
            loop: true,
            freeMode: true,  //设置为true则变为free模式
            allowTouchMove: false,
            simulateTouch: false,
            slidesPerView: 4,
            slidesPerGroup: 1,
            onSlideClick: function (swiper) {
                if(document.getElementById('iShow').className == 'noShow'){
                    document.getElementById('lightboxOverlay').style.display= 'block';
                    document.getElementById('imgBox').src= swiper.clickedSlide.children[0].currentSrc;
                }
            }
        })
        //--  如果需要前进后退按钮 --
        $('.arrow-left').click(function () {
            mySwiper.swipePrev();
        });
        $('.arrow-right').click(function () {
            mySwiper.swipeNext();
        });
    })
    export default {
        name: "SwiperView",
        props: {
            'imgList': Array,
            'ids': String,
        },
        data() {
            return {
                dialogTableVisible: false,
                imgUrl: '',
            }
        },
        mounted() {

            let that = this;
            //轮播图
            mySwiper = new Swiper('.mn-swiper', {
                autoplay: false,
                touchRatio: 1,
                speed: 1000,  //匀速时间
                loop: true,
                freeMode: true,  //设置为true则变为free模式
                allowTouchMove: false,
                simulateTouch: false,
                slidesPerView: 4,
                slidesPerGroup: 1,
                onSlideClick: function (swiper) {
                    if(document.getElementById('iShow').className == 'noShow'){
                       document.getElementById('lightboxOverlay').style.display= 'block';
                       document.getElementById('imgBox').src= swiper.clickedSlide.children[0].currentSrc;
                    }
                }
            })
            $('.arrow-left').click(function () {
                mySwiper.swipePrev();
            });
            $('.arrow-right').click(function () {
                mySwiper.swipeNext();
            });
        },
        methods: {
            closeBox(){
                document.getElementById('lightboxOverlay').style.display= 'none';
            },
            getImgUrl(url) {
                return getImgUrl(url)
            },
        }
    };
</script>

<style scoped lang="less">
    .swiperContentBox {
        clear: both;
        position: relative;
    }
    .mn-wrap {
        width: 90%!important;
        margin: 0 auto;
        img {
            cursor: pointer;
        }
    }
    .otherDivWidth {
        width: 1092px;
    }

    .longDiv {
        width: 1072px;
    }
    .otherWidth {
        width: 273px !important;
        margin: 0 0px;
        border: none;
    }

    .longImg {
        width: 536px !important;
        margin: 0 0px;
        border: none;
    }
    .lightboxOverlay {
        /* background: black;  */
        background-color: #000;
        background: rgba(0, 0, 0, 0.5);
        position: fixed; 
        left: 0px; 
        top: 0px; 
        width: 100%; 
        height: 100%; 
        /* display: none;  */
        z-index: 888; 
        /* filter: alpha(opacity=50);  */
        /* opacity: 0.5 !important; */
        .imgDiv{
            position: fixed; 
            /* display: none;  */
            border-color: #cccc99;
            left: 20%;
            top: 0.6rem;
            /*margin-top: -200px; !*--高度的一半--*! */
            /*margin-left: -450px; !*--宽度的一半--*!*/
            opacity: 1 !important;
            .imgBox{
              border-radius: 5px;
              display: block;
              width: 5.7rem;
              height: 4rem;
            }
          .lightbox {
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 10000;
            text-align: center;
            line-height: 0;
            font-weight: normal;
          }
            .closeIcon{
                background: url(../assets/close.png) no-repeat;
                position: absolute;
                top: -0.3rem;
                right: -0.3rem;
                width: 34px;
                height: 34px;
                cursor: pointer;
            }
        }
        
    }
</style>
