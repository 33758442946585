<template>
    <div v-if="childInfoMap">
        <div style="padding: 0 30px" >
            <p class="state noPaddingTop" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 1" v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content"></p>
          <!--    左图右文本-->
          <div class="contenBox" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 2">
            <div class="imgBox"><img :src="getImgUrl(childInfoMap.content.imgUrl)" /></div>
            <div  v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content"  class="htmlBox"></div>
          </div>
          <!--    左文本右图-->
          <div class="contenBox" v-if="childInfoMap && childInfoMap.content && childInfoMap.content.type == 3">
            <div  v-html="childInfoMap && childInfoMap.content && childInfoMap.content.content" class="htmlBox"></div>
            <div class="imgBox"><img :src="getImgUrl(childInfoMap.content.imgUrl)" /></div>
          </div>
            <div class="ygfcscroll">
                <div id="ygfcscroll-btn"></div>
                <a href="javascript:" id="ygfcscroll-prev" class="ygfcscroll-prev" @click="btnClick('left')"></a>
                <a href="javascript:" id="ygfcscroll-next" class="ygfcscroll-next" @click="btnClick('right')"></a>
                <div id="ygfcscroll-box" class="ygfcscroll-box">
                    <ul class="clearfix" :id="childInfoMap && childInfoMap.content && childInfoMap.content.headlineId" :key="childInfoMap && childInfoMap.content && childInfoMap.content.headlineId">
                        <li v-for="itemImg in childInfoMap.contentImg" :key="itemImg.id">
                            <a class="example-image-link" data-lightbox="example-1">
                                <img :src="getImgUrl(itemImg.pictureUrl)" class="example-image" width="225" height="198"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {  getImgUrl } from '@/api/index.js';
    export default {
        props: {
            'childInfoMap': Object,
        },
        data() {
            return {
            };
        },
        created() {},
        methods: {
            btnClick(flag){
                if(this.childInfoMap.contentImg && this.childInfoMap.contentImg.length >=4){
                    let ulDiv = document.getElementById(this.childInfoMap && this.childInfoMap.content && this.childInfoMap.content.headlineId);
                    console.log(ulDiv.childNodes,'==ulDiv.childNode')
                    let liOne = ulDiv.getElementsByTagName('li')[0];
                    let liFive = ulDiv.getElementsByTagName('li')[this.childInfoMap.contentImg.length -1];
                    console.log(liFive,'==liFive')
                    if(flag == 'left') {
                        // this.isShow = false;
                        ulDiv.childNodes[1].before(liFive);
                        // this.isShow = true;
                    } else {
                        ulDiv.childNodes[this.childInfoMap.contentImg.length + 1].before(liOne);
                    }
                }
            },
            getImgUrl(url) {
                return getImgUrl(url)
            },
        },
    };
</script>

<style lang="less" scoped>
    #app {
        text-align: left;
    }
    .title {
        border-left: 4px solid #ee020d;
        height: 26px;
        line-height: 26px;
        padding-left: 10px;
        font-size: 22px;
        margin: 40px 0;
        font-weight: normal;
    }
    .state {
        width: 80%;
        margin: 0 auto;
        font-size: 16px;
        text-indent: 30px;
        color: #666;
    }
    .noPaddingTop {
      padding-top: 0!important;
    }
    .contenBox{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        padding: 10px;
      }
      .imgBox{width: 30%};
      .htmlBox{width: 70%};
    }
    .friend {
        width: 80%;
        margin: 0 auto;
        li {
            float: left;
            border: 1px solid #ccc;
            margin: 15px;
            list-style-type: none;
            img {
                width: 153px;
                height: 52px;
            }
        }
    }
    .ygfcscroll{
        position:relative;
        padding:40px 0px 20px;
        color:#999;
        margin-left:-30px;
    }
    .ygfcscroll-box{width:940px;margin:0 auto 50px;overflow:hidden;}
    .ygfcscroll-prev,.ygfcscroll-next{
        position:absolute;
        top:42%;
        width:32px;
        height:32px;
        background:url(../../../assets/point02.png) no-repeat 0 0;
        opacity: .5
    }
    .ygfcscroll-prev{left:2%;background-position: 0 0;}
    .ygfcscroll-next{right:2%;background-position: -35px 0;}
    .ygfcscroll-prev:hover,.ygfcscroll-next:hover{opacity:9}
    .ygfcscroll-box ul{width:9999px;position:relative;}
    .ygfcscroll-box li{
        float:left;
        width:2.25%;
        border:1px solid #e5e5e5;
        margin-right:10px;
        position: relative;
        list-style-type: none;
    }
    .ygfcscroll-box li a{color:#666;}
    #ygfcscroll-btn{display:none;}
</style>
