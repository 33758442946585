<template>
  <div class="container">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="state" v-html="contentMap && contentMap.content" v-if="contentMap.content && contentMap.type == 1"></div>
    <!--    左图右文本-->
    <div class="contenBox" v-if="contentMap && contentMap.type == 2">
      <div class="imgBox"><img :src="getImgUrl(contentMap.imgUrl)" /></div>
      <div  v-html="contentMap.content" class="htmlBox"></div>
    </div>
    <!--    左文本右图-->
    <div class="contenBox" v-if="contentMap.content && contentMap.type == 3">
      <div  v-html="contentMap.content" class="htmlBox"></div>
      <div class="imgBox"><img :src="getImgUrl(contentMap.imgUrl)" /></div>
    </div>
    <div class="doc" v-for="item in childHeadline" :key="item.headlineEntity.id">
      <h3>{{ item.headlineEntity.titleName }}</h3>
      <ul v-if="item.headlineEntity.id ==='1430433809811111936'" class="first_content">
        <li v-for="itemOne in item.content" :key="itemOne.id">
          <div class="fl" v-html="itemOne.content"></div>
          <div class="fr">
            <img :src="getImgUrl(itemOne.picture)" alt=""/>
          </div>
        </li>
      </ul>
      <ul v-else-if="item.headlineEntity.id ==='1430433969697980416'"  class="second_content">
        <li  v-html="item.contentEntity.content"> </li>
        <li v-for="itemTwo in item.content" :key="itemTwo.id">
          <div class="fl" v-html="itemTwo.content"></div>
          <div class="fr">
            <img :src="getImgUrl(itemTwo.picture)" alt=""/>
          </div>
        </li>
      </ul>
      <div class="excitation third_content" v-else-if="item.headlineEntity.id ==='1430434060106203136'" >
        <p class="tit" v-html="item.content.content"></p>
        <ol>
          <li v-for="itemThree in item.childHeadline" :key="itemThree.id">
            <h2>{{ itemThree.headlineEntity.titleName }}</h2>
            <div class="divThree" v-html="itemThree.content.content"></div>
          </li>
        </ol>
      </div>
      <div v-else>null</div>
    </div>
    <!-- v-bind:style="{'top': contentMap.type != 1 ? '650px':'350px'}" -->
    <ul class="rcjsNav fixBox" id="float_ad"  >
      <li v-for="(liItem, index) in childHeadline" :key="liItem.headlineEntity.id"><a href="javascript:;" @click="liClickFnc(liItem.headlineEntity.id)" class="first">{{ liItem.headlineEntity.titleName }}</a></li>
      <!-- <li @click="liClickFnc('second_content')"><a href="javascript:;" class="second">人才发展</a></li>
      <li @click="liClickFnc('third_content')"><a href="javascript:;" class="third">人才激励</a></li> -->
    </ul>
  </div>
</template>
<script>
import { Personnel_list, getImgUrl } from '@/api/index.js';
export default {
  data() {
    return {
      childHeadline: [], // 各模块数据集合
      titlePicture: "", // 标题图片
      contentMap:{},
      scroll: 0,
      titleName: '',
    };
  },
  created() {
    this.getData();
    this.scrollFnc();
  },
  mounted() {
    // let bodyHeight = document.getElementsByTagName("body")[0].scrollTop;
    // let htmlHeight = document.getElementsByTagName("html")[0].scrollTop;
    // if(bodyHeight >= 300 || htmlHeight>= 300){
    //  document.getElementsByClassName("rcjsNav")[0].className = 'rcjsNav fixBox';
    // }
    window.addEventListener('scroll', this.scrollFnc, true)
  },
  methods: {
    scrollFnc(){
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let bodyHeight = document.getElementsByTagName("body")[0].scrollTop;
      let htmlHeight = document.getElementsByTagName("html")[0].scrollTop;
      if(bodyHeight >= 300 || htmlHeight>= 300){
        document.getElementsByClassName("rcjsNav")[0].className = 'rcjsNav fixBox';
      }
    },
    liClickFnc(id){ // l滚动
      let flag = '';
      if(id === '1430433809811111936'){
        flag = 'first_content';
      } else if (id === '1430433969697980416'){
        flag = 'second_content'
      } else if(id === '1430434060106203136'){
        flag = 'third_content'
      } else {
        flag = '';
      }
      window.scroll({
        top: document.getElementsByClassName(flag)[0].offsetTop - 50,
        behavior: "smooth"
      })
    },
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      Personnel_list(data).then(res=> {
        if(res.success){
          this.childHeadline = res.data.childHeadline;
          this.titlePicture = getImgUrl(res.data.headlineImg.picture);
          this.contentMap = res.data.content;
        }
      })
      .catch(error=>{
          console.log(error)
      });
    },
    getImgUrl(url) {
      return getImgUrl(url)
    },
  },
};
</script>
<style lang="less">
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .state {
    padding: 45px 0 20px;
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
    text-indent: 30px;
    color: #666;
  }
  .contenBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  div{
    padding: 10px;
  }
  .imgBox{width: 30%};
  .htmlBox{width: 70%};
}
  .doc {
    padding: 0 0 10px 30px;

    h3 {
      border-left: 4px solid #ee020d;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      font-size: 22px;
      margin: 40px 0;
      font-weight: normal;
      margin-bottom: 0;
    }
    .second_content {
      .fl {
        text-indent: 0;
      }
    }
    .third_content {
      min-height: 500px;
    }
    ul {
      width: 90%;
      margin-left: 1%;
      font-size: 16px;
      min-width: 900px;
      max-width: 1200px;
      li {
        padding: 30px 0;
        border-bottom: 1px solid #e5e5e5;
        list-style: none;
        display: inline-block;
        /*width: auto;*/
         width: 100%;
        .fl {
          width: 58%;
          float: left;
          margin-top: 3%;
          text-indent: 33px;
          em {
            font-size: 18px;
            color: #9f7c46;
            font-weight: bold;
            font-style: normal;
          }
          .actit {
            color: #a90621;
            font-weight: bold;
            text-indent: 0;
            margin-left: -33px;
          }
        }
        .fr {
          float: right;
          width: 40%;
          text-align: right;
          img {
            max-width: 375px;
            width: 100%;
          }
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
    .excitation {
      .tit {
        font-size: 16px;
        margin: 20px 0 0 15px;
      }
      ol {
        width: 90%;
        height: 300px;
        list-style: none;
        li {
          width: 31%;
          margin-left: 2%;
          float: left;
          position: relative;
          border: none;
          h2 {
            height: 50px;
            border-radius: 10px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            background: #a90621;
            margin: 30px 0;
          }
          .divThree {
            text-indent: 25px;
            border-right: 1px solid #e5e5e5;
            padding: 0 10px;
            height: 200px;
            font-size: 16px;
            color: #666;
            line-height: 27px;
            position: absolute;
            width: 97%;
          }
        }
        li:nth-of-type(3) p {
          /*border: none;*/
          /*text-align: center;*/
          /*margin-top: -27px;*/
          text-indent: 0px;
        }
      }
    }
  }
  .fixBox{
    position:fixed;
    top: 350px;
  }
  .rcjsNav{
    /*margin-left: 71%;*/
    right: 1rem;
    border-left: 1px solid #e2e2e2;
    /*padding-top: 10px;*/
    padding-top: 0.1rem;
    li {
      list-style-type: none;
      margin-bottom: 50px;
      padding-left: 10px;
      position: relative;
      font-size: 12px;
      cursor: pointer;
      a {
        text-decoration: none;
        outline: none;
        color: #333;
        cursor: pointer;
      }
      a:hover{color:#ee020d;}
    }
    li:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #e2e2e2;
      border-radius: 50%;
      display: inline-block;
      margin-left: -16px;
      margin-right: 5px;
    }
    li:hover:before{background:#ee020d;}
  }
}
span {
  display: inline;
}
@media screen and (min-width: 1500px) {
  .container .rcjsNav  {
    right: 2.5rem!important;
  }
}
</style>
