<template>
  <div class="container">
    <div class="banner">
      <img
        :src="data.headlineImg.picture"
        v-if="data.headlineImg.picture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="docDetails">
      <ul class="navbar">
        <li v-for="(item, index) in data.childHeadline" :key="index">
          <a
            href="javascript:void(0);"
            @click="navbar(item.headlineEntity.id)"
            :class="id == item.headlineEntity.id ? 'ahover' : ' '" >
            {{item.headlineEntity.titleName}}
          </a>
        </li>
      </ul>
      <ul class="list">
        <li @click="listDetails(item.id)"  v-for="item in dataList" :key="item.id">
          <div class="fl">
            <img
              :src="getImgUrl(item.picture)"
              alt=""
            />
          </div>
          <div class="frDetails">
            <h3>{{item.title}}</h3>
          </div>
          <div class="clear"></div>
        </li>
      </ul>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :background="true"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import { Colourful_ChildInfo_list,  getImgUrl } from '@/api/index.js';
export default {
  data() {
    return {
      id: "",
      data: {
        headlineImg: {}
      }, // 参数
      dataList: [],
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
      },
      titleName: '',
    };
  },
  created() {
    this.id = this.$route.query.id;
    const data = sessionStorage.getItem('data')
    this.titleName = JSON.parse(sessionStorage.getItem('titleName'))
    this.data = JSON.parse(data);
    this.getData(this.$route.query.id)
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getData(this.id);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getData(this.id);
    },
    navbar(type) {
      this.id = type;
      this.pagination = {
        currentPage: 1,
        total: 0,
        pageSize: 10,
      }
      this.getData(type);
    },
    getImgUrl(url) {
      return getImgUrl(url)
    },
    getData(id) {
      console.log(this.pagination)
      const data={data:{headlineId: id}, page: this.pagination.currentPage, row: this.pagination.pageSize }
      Colourful_ChildInfo_list(data).then(res=> {
        if (res.data) {
          this.dataList = res.data.list;
          this.pagination = {
            currentPage: res.data.pageNum,
            total: Number(res.data.total),
            pageSize: res.data.pageSize,
          }
        }
      }).catch(error=>{
        console.log(error)
      });
    },
    listDetails(item) {
      this.$router.push({ path: "/listdetails", query: { id: item } });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .docDetails {
    padding: 0 0 50px 30px;
    .navbar {
      background: url(http://www.juyike.cn/Public/Home/images/jyknavBg.jpg)
        no-repeat 0 bottom;
      width: 80%;
      margin: 30px auto;
      list-style-type: none;
      li {
        width: 25%;
        height: 115px;
        float: left;
        text-align: center;
        cursor: pointer;
        a {
          color: #ee020d;
          font-size: 20px;
          height: 115px;
          line-height: 115px;
          display: inline-block;
          width: 100%;
          text-decoration: none;
        }
        a:hover {
          background: url(http://www.juyike.cn/Public/Home/images/hover.jpg)
            no-repeat;
          color: #fff;
          background-size: 100% 115px;
        }
      }
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #a90621;
      border-color: #ccc;
    }
    .el-pager {
      min-width: auto!important;
      width: auto!important;
      margin: 0!important;
      .number {
        width: auto !important;
        padding: 0 4px !important;
      }
      li {
        width: auto !important;
        padding: 0 4px !important;
      }
    }
    /deep/ .el-pager {
      min-width: auto!important;
      width: auto!important;
      margin: 0!important;
      li {
        width: auto !important;
        padding: 0 4px !important;
      }
      number {
        width: auto !important;
        padding: 0 4px !important;
      }
    }
    /deep/ .el-pagination {
      text-align: center;
      font-size: 0;
      color: #666;
      margin-top: 40px;
      clear: both;
    }

    .navbar:after {
      content: "\20";
      display: block;
      clear: both;
      height: 0;
      overflow: hidden;
    }
    .list {
      padding-right: 30px;
      li {
        border-bottom: 1px solid #e5e5e5;
        padding: 30px 35px;
        display: flow-root;
        clear: both;
        box-sizing: border-box;
        .clear {
          clear: both;
          height: 0;
        }
        .fl {
          width: 24%;
          float: left;
          display: block;
          img {
            max-width: 376px;
            min-width: 280px;
            width: 100%;
            cursor: pointer;
          }
        }
        .frDetails {
          float: right;
          width: 69%;
          font-size: 16px;
          h3 {
            font-size: 18px;
            margin-bottom: 2%;
            font-weight: normal;
            cursor: pointer;
            border: none;
          }
        }
      }
      li:hover {
        color: #aa001b;
      }
    }
  }
}
.ahover {
  background: url(http://www.juyike.cn/Public/Home/images/hover.jpg) no-repeat;
  color: #fff !important;
  background-size: 100% 115px;
}
</style>
