<template>
  <div class="container">
    <div class="banner">
      <img
        :src="enterpricedata.headlineImg.picture"
        v-if="enterpricedata.headlineImg.picture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <div class="state" v-html="enterpricedata.content.content">
      </div>
      <ul>
        <li v-for="(item, index) in enterpricedata.childHeadline" :key="index" @click="details(item.headlineEntity)">
          <a href="javascript:void(0);" >
            <span>{{item.headlineEntity.titleName}}</span>
            <img v-for="(source, key) in (item.contentImg || [])" :key="key" :src="getImgUrl(source.pictureUrl)" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Colourful_list,  getImgUrl } from '@/api/index.js';
export default {
  data() {
    return {
      enterpricedata:{
          headlineImg: {},
          content: {},
      },
      titleName: '',
    };
  },
  created() {
    this.getData();
    
  },
  methods: {
    getImgUrl(url) {
      return getImgUrl(url)
    },
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      Colourful_list(data).then(res=> {
        console.log(res.data,'多彩聚益科');
        this.enterpricedata = res.data;
        this.enterpricedata.headlineImg.picture = getImgUrl(res.data.headlineImg.picture);
      })
      .catch(error=>{
          console.log(error)
      });
    },
    details(type) {
      sessionStorage.setItem("data", JSON.stringify(this.enterpricedata));
      sessionStorage.setItem("titleName", JSON.stringify(this.titleName))
      this.$router.push({ path: "/details", query: { id: type.id } });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .doc {
    padding: 0 0 50px 30px;
    .state {
      padding: 45px 0 20px;
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      text-indent: 30px;
      color: #666;
    }
    ul {
      margin: 0 0 0 11%;
      width: 88%;
      li {
        width: 38%;
        float: left;
        margin: 2% 3%;
        padding: 0;
        list-style: none;
        border: none;
        a {
          padding: 15px;
          background: #eee;
          border: 1px solid #eee;
          display: block;
          text-decoration: none;
          span {
            font-weight: bold;
            color: #ee020d;
            font-size: 16px;
          }
          img {
            max-width: 486px;
            margin-top: 3px;
            height: 210px;
            width: 100%;
          }
        }
        a:hover {
          background: #fff;
          border-color: #dcdcdc;
        }
      }
    }
  }
}
</style>
