<template>
  <div class="container widthBox">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true" lazy="true">
        <el-tab-pane :label="item.headlineEntity.titleName"  v-for="(item,index) in childHeadline" :key="item.headlineEntity.id" :name="index == 0 ? 'first':index ==1 ? 'second':index==2? 'three':index==3?'four':'kong'">
        </el-tab-pane>
      </el-tabs>
       <!--财富管理 item.headlineEntity.id === '1430781544934408192' && -->
       <div v-if="isFirst && childHeadline && childHeadline.length > 0 ">
          <WealthManage v-if="isFirst && childHeadline[0].headlineEntity.id === '1430781544934408192'" :childInfoMap="childInfoMap" :dataMapInfo="dataMapInfo"></WealthManage>
          <PeInvestment v-else-if="isFirst && childHeadline[0].headlineEntity.id === '1430781612668223488'" :childInfoMap="childInfoMap"></PeInvestment>
          <mineInvestment v-else-if="isFirst && childHeadline[0].headlineEntity.id === '1430781689642090496'" :childInfoMap="childInfoMap" ></mineInvestment>
          <listedCompany v-else-if="isFirst && childHeadline[0].headlineEntity.id === '1430781753567477760'" :childInfoMap="childInfoMap" ></listedCompany>
          <elseView v-else :childInfoMap="childInfoMap" :key="childHeadline[0].headlineEntity.id"></elseView>
       </div>
       <!-- PE投资 item.headlineEntity.id === '1430781612668223488' && -->
       <div v-if="isSecond && childHeadline && childHeadline.length > 0 ">
          <WealthManage v-if="isSecond && childHeadline[1].headlineEntity.id === '1430781544934408192'" :childInfoMap="childInfoMap" :dataMapInfo="dataMapInfo"></WealthManage>
          <PeInvestment v-else-if="isSecond && childHeadline[1].headlineEntity.id === '1430781612668223488'" :childInfoMap="childInfoMap"></PeInvestment>
          <mineInvestment v-else-if="isSecond && childHeadline[1].headlineEntity.id === '1430781689642090496'" :childInfoMap="childInfoMap" ></mineInvestment>
          <listedCompany v-else-if="isSecond && childHeadline[1].headlineEntity.id === '1430781753567477760'" :childInfoMap="childInfoMap" ></listedCompany>
          <elseView v-else :childInfoMap="childInfoMap" :key="childHeadline[1].headlineEntity.id"></elseView>
       </div>
       
       <!-- 矿业投资 item.headlineEntity.id === '1430781689642090496' &&  === -->
       <div v-if="isThree && childHeadline && childHeadline.length > 0 ">
          <WealthManage v-if="isThree && childHeadline[2].headlineEntity.id === '1430781544934408192'" :childInfoMap="childInfoMap" :dataMapInfo="dataMapInfo"></WealthManage>
          <PeInvestment v-else-if="isThree && childHeadline[2].headlineEntity.id === '1430781612668223488'" :childInfoMap="childInfoMap"></PeInvestment>
          <mineInvestment v-else-if="isThree && childHeadline[2].headlineEntity.id === '1430781689642090496'" :childInfoMap="childInfoMap" ></mineInvestment>
          <listedCompany v-else-if="isThree && childHeadline[2].headlineEntity.id === '1430781753567477760'" :childInfoMap="childInfoMap" ></listedCompany>
          <elseView v-else :childInfoMap="childInfoMap" :key="childHeadline[2].headlineEntity.id"></elseView>
       </div>
       <!--上市公司-->
       <div v-if="isFour && childHeadline && childHeadline.length > 0 ">
          <WealthManage v-if="isFour && childHeadline[3].headlineEntity.id === '1430781544934408192'" :childInfoMap="childInfoMap" :dataMapInfo="dataMapInfo"></WealthManage>
          <PeInvestment v-else-if="isFour && childHeadline[3].headlineEntity.id === '1430781612668223488'" :childInfoMap="childInfoMap"></PeInvestment>
          <mineInvestment v-else-if="isFour && childHeadline[3].headlineEntity.id === '1430781689642090496'" :childInfoMap="childInfoMap" ></mineInvestment>
          <listedCompany v-else-if="isFour && childHeadline[3].headlineEntity.id === '1430781753567477760'" :childInfoMap="childInfoMap" ></listedCompany>
          <elseView v-else :childInfoMap="childInfoMap" :key="childHeadline[3].headlineEntity.id"></elseView>
       </div>
    </div>
  </div>
</template>
<script>
import WealthManage from "./components/WealthManage.vue";
import PeInvestment from "./components/PeInvestment.vue";
import mineInvestment from "./components/mineInvestment.vue";
import listedCompany from './components/listedCompany.vue';
import elseView from './components/elseView.vue';
import { info_list, getImgUrl, info_childInfo_list, info_childSonInfo_list } from '@/api/index.js';
export default {
  components: {
    WealthManage,
    PeInvestment,
    mineInvestment,
    listedCompany,
    elseView,
  },
  data() {
    return {
      activeName: "first",
      childId: "1430781544934408198",
      titlePicture:'',
      childHeadline:[],
      childInfoMap:{},
      dataMapInfo: null,
      titleName: '',
      isFirst: true,
      isSecond: false,
      isThree: false,
      isFour: false,
    };
  },
  created() {
    this.getData();
    
  },
  methods: {
    getData(){
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
        let item = menuList.filter(item => item.path === this.$route.path);
        this.titleName = item[item.length-1].titleName;
        const data = {id:item[item.length-1].id};
      info_list(data).then(res=> {
        if(res.success){
          this.childHeadline = res.data.childHeadline;
          this.titlePicture = getImgUrl(res.data.headlineImg.picture);
          this.handleClick(res.data && res.data.childHeadline && res.data.childHeadline.length > 0 ? res.data.childHeadline[0].headlineEntity.id : '');
          this.getChildList()
        }
      })
      .catch(error=>{
          console.log(error)
      });
    },
    handleClick(tab) {
      let data = {id: ''};
      if(typeof tab === 'string') {
        data.id = tab;
      } else {
        if(tab && tab.props && tab.props.name === 'first'){
          data.id = this.childHeadline[0].headlineEntity.id;
          this.isFirst = true;
          this.isSecond = false;
          this.isThree = false;
          this.isFour = false;
        } else if(tab && tab.props && tab.props.name === 'second'){
          data.id = this.childHeadline[1].headlineEntity.id;
          this.isFirst = false;
          this.isSecond = true;
          this.isThree = false;
          this.isFour = false;
        } else if(tab && tab.props && tab.props.name === 'three'){
          data.id = this.childHeadline[2].headlineEntity.id;
          this.isFirst = false;
          this.isSecond = false;
          this.isThree = true;
          this.isFour = false;
        } else if(tab && tab.props && tab.props.name === 'four'){
          data.id = this.childHeadline[3].headlineEntity.id;
          this.isFirst = false;
          this.isSecond = false;
          this.isThree = false;
          this.isFour = true;
        } else {
          this.isFirst = false;
          this.isSecond = false;
          this.isThree = false;
          this.isFour = false;
        }
      }
      info_childInfo_list(data).then(res=> {
        if(res.success){
          this.childInfoMap = res.data;
        }
      })
      .catch(error=>{
        console.log(error)
      });
    },
    getChildList() {
      const data = {id: this.childId}
      info_childSonInfo_list(data).then(res=> {
        if(res.success){
          this.dataMapInfo = res.data
        }
      })
      .catch(error=>{
        console.log(error)
      });
    }
  },
};
</script>
<style lang="less" >
.widthBox {
  max-width: 1345px !important;
}
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  /* max-width: 1200px; */ 
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  img {
      width: 100%;
      height: 230px;
  }
  }
  // tab切换
  .el-tabs__nav {
    height: 56px;
    padding: 20px 0;
    .el-tabs__item {
      height: 100%;
      line-height: 56px;
      font-size: 25px;
      border-right: 2px solid #ccc;
    }
    .el-tabs__item:nth-last-of-type(1) {
      border-right: none;
    }
    .el-tabs__item:hover {
      color: #a90621;
    }
    .el-tabs__item.is-active {
      color: #a90621;
    }
    .el-tabs__active-bar {
      background-color: #a90621;
    }
  }
  .el-tabs__nav-wrap::after {
    content: none;
  }
}
</style>
