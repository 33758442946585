<template>
    <div class="containerTableBox">
        <!-- 纵向列表  -->
        <div class="excitationTable" v-if="childInfoMap && childInfoMap.type === 'portraitList'">
            <div class="tableContentItem" v-for="item in childInfoMap && childInfoMap.contents" :key="item.id"><ContentView :childInfoMap="{...item, contents: item}" ></ContentView></div>
        </div>
        <!-- 横向列表  -->
        <div class="excitationAcrossTable" v-if="childInfoMap && childInfoMap.type === 'transverseList'">
            <ol>
                <li v-for="item in childInfoMap && childInfoMap.contents" :key="item.id">
                    <h2>{{ item.titleName }}</h2>
                    <div class="divThree" v-html="item.content"></div>
                </li>
            </ol>
        </div>
    </div>

</template>

<script>
    import ContentView from './content'
    export default {
        props: {
            'childInfoMap': Object,
        },
        components: {
            ContentView,
        },
    }
</script>

<style lang="less" scoped>
.containerTableBox {
    .excitationTable {
        .tableContentItem {
            border-bottom: 1px solid #e5e5e5;
        }
        .tableContentItem:last-child {
            border-bottom: none;
        }
    }
    .excitationAcrossTable {
        min-height: 500px;
        .tit {
            font-size: 16px;
            margin: 20px 0 0 15px;
        }
        ol {
            min-height: 300px;
            list-style: none;
            li {
                width: 31%;
                float: left;
                margin-left: 2%;
                position: relative;
                border: none;
                h2 {
                    height: 50px;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 50px;
                    color: #fff;
                    font-size: 20px;
                    background: #a90621;
                    margin-bottom: 30px;
                }
                .divThree {
                    border-right: 1px solid #e5e5e5;
                    padding: 0 10px;
                    height: 200px;
                    font-size: 16px;
                    color: #666;
                    line-height: 27px;
                    position: absolute;
                    width: 97%;
                }
            }
        }
    }
}
</style>
