<template>
  <div class="container">
    <div class="banner">
      <img
        :src="titlePicture"
        v-if="titlePicture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <div class="relative" v-for="item in childHeadline" :key="item.headlineEntity.id">
        <h3 class="title">{{ item.headlineEntity.titleName}}</h3>
        <span class="more" v-if="item.headlineEntity.id === '1431102500051615744'" @click="moreClickFnc()">查看更多>></span>
        <div class="clearfix" v-if="item.headlineEntity.id === '1431102500051615744'">
          <a :href="itemOne.link" target="_blank" class="video" v-for="(itemOne, oneIndex) in item.content" :key="itemOne.id" :class="(oneIndex >=2 && moreFlag) || (oneIndex <= 1) ? 'showAdiv' : 'hideAdiv'" >
            <span></span>
            <img :src="getImgUrl(itemOne.picture)"/><br />
            {{ itemOne.titleName}}
          </a>
        </div>
        <SwiperView v-if="item.headlineEntity.id === '1431102596612882432'" :imgList="item.content" :ids="9999"></SwiperView>
        <div class="selectBox" v-if="item.headlineEntity.id === '1431102647246520320'">
          <el-select v-model="pdfId" @change="changeSelect" placeholder="请选择">
            <el-option
                    v-for="items in selectInfo"
                    :key="items.id"
                    :label="items.pdfName"
                    :value="items.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="elSwiper">
          <el-carousel indicator-position="none" :loop="false" :autoplay="!item.headlineEntity.id === '1431102647246520320'" ref="carouselList" v-if="item.headlineEntity.id === '1431102647246520320' && isShowCarousel" :style="carouselWidth.toString() + 'px'" :height="carouselHeight.toString() + 'px'">

            <el-carousel-item v-for="(itemThird, index) in imgInfoList" :key="index">
              <img :src="geturl(itemThird.itemUrl)" class="example-image"  @click="openFileUrl">
            </el-carousel-item>
          </el-carousel>
        </div>
        
        <!-- <div class="ygfcscrolls" v-if="item.headlineEntity.id === '1431102647246520320'">
          <div id="ygfcscroll-btns"></div>
          <a href="javascript:" id="ygfcscroll-prevs" class="ygfcscroll-prev" @click="btnClickJuShuo('left')"></a>
          <a href="javascript:" id="ygfcscroll-nexts" class="ygfcscroll-next" @click="btnClickJuShuo('right')"></a>
          <div id="ygfcscroll-boxs" class="ygfcscroll-boxs">
            <ul class="clearfixs" id="clearfixs">
              <li v-for="itemThird in imgInfoList" :key="itemThird.itemUrl">
                <a class="example-image-link" data-lightbox="example-1">
                  <img :src="itemThird.itemUrl" class="example-image" width="887" height="887"></a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { StaffPresence_list, getImgUrl, pdfSelectUrl, pdfInfoUrl } from '@/api/index.js';
import SwiperView from '../../components/swiperView.vue'
export default {
  components:{
    SwiperView,
  },
  data() {
    return {
      childHeadline: [], // 各模块数据集合
      titlePicture: "", // 标题图片
      moreFlag: false,
      titleName:'',
      iSpeed: 200,
      selectInfo: [],
      pdfId: '',
      imgInfoList: [],
      isShowCarousel: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getImgUrl(url) {
      return getImgUrl(url)
    },
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      StaffPresence_list(data).then(res=> {
            if(res.success){
              this.childHeadline = res.data.childHeadline;
              this.titlePicture = getImgUrl(res.data.headlineImg.picture);
              const contentList = res.data.childHeadline.find(item => item.headlineEntity && item.headlineEntity.id ==='1431102500051615744');
              const data = {
                  titlePicture: this.titlePicture,
                  titleName: this.titleName,
                  content: contentList || {},
              }
              sessionStorage.setItem('staffInfo', JSON.stringify(data))
            }
      })
      .catch(error=>{
          console.log(error)
      });
      pdfSelectUrl().then(res=> {
        if(res.success){
          this.selectInfo = res.data;
          this.pdfId = res.data&& res.data.length > 0 ? res.data[0].id : '';
          if(res.data && res.data.length > 0 ){
            this.changeSelect(res.data[0].id);
          }
        }
      })
      .catch(error=>{
        console.log(error)
      });
    },
    geturl (url) {
      return getImgUrl(url)
    },
    changeSelect(e) {
      this.selectInfo.forEach((item,index) => {
        if(item.id === e) {
          this.$nextTick(() => {
            this.carouselHeight = item.height
            this.carouselWidth = item.width
          })
        }
      })
      this.$nextTick(() => {
        this.isShowCarousel = false
      })
      // const dataItem = this.selectInfo.find(item => item.id === this.pdfId);
      // console.log(dataItem,e, 'dataItem')
      // if (dataItem) {
      //   this.showPdf = false
      //   this.pdfContainerInfo = {
      //     width: dataItem.width,
      //     height: dataItem.height,
      //   }
      //   this.showPdf = true
      //   this.setPdfContainer()
      // }
      let isSafari = /Safari/.test(navigator.userAgent);
      let isChrome = /Chrome/.test(navigator.userAgent);
      let browserType = 1; // 1-》 chrome 2 -》360浏览器3-》QQ浏览器4-》Safari5-》火狐6-》ie浏览器7-》edge
      if (isSafari && !isChrome) {
        browserType = 4;
      }
      const data = {id: e, browserType};
      pdfInfoUrl(data).then(res => {
        if (res.success && res.data && res.data[0]) {
          this.$nextTick(() => {
            this.imgInfoList = res.data
          })
          setTimeout(() => {
            this.$nextTick(() => {
              this.isShowCarousel = true
            })
          },300)
          // this.imgInfoList = res.data;
          // this.showPdf = true;
        }
      }).catch(error => {
        console.log(error)
      });
    },
    moreClickFnc(){
      // this.moreFlag = true;
      this.$router.push({ path: "/staffMore" });
    },
    btnClick(flag){
      let ulDiv = document.getElementById('clearfix');
      let liOne = ulDiv.getElementsByTagName('li')[0];
      let liFive = ulDiv.getElementsByTagName('li')[4];

      if(flag == 'left') {
        ulDiv.childNodes[1].before(liFive);
      } else {
        ulDiv.childNodes[6].before(liOne);
      }
    },
    btnClickJuShuo(flag){
      let ulDiv = document.getElementById('clearfixs');
      let liOne = ulDiv.getElementsByTagName('li')[0];
      let liFive = ulDiv.getElementsByTagName('li')[this.imgInfoList.length -1];

      if(flag == 'left') {
        ulDiv.childNodes[1].before(liFive);
      } else {
        ulDiv.childNodes[this.imgInfoList.length+1].before(liOne);
      }
    },
    openFileUrl(){
      const dataItem = this.selectInfo.find(item => item.id === this.pdfId);
      if(dataItem && dataItem.fileUrl) {
        const urlNew = getImgUrl(dataItem.fileUrl);
        // 'http://testattachment.fuguowealth.com/'+ dataItem.fileUrl;
        window.open(urlNew)
      }
      // window.open("http://attachmentfxh.fgdtfund.com/test_infopulish_attachment_1461216475560804352.pdf");
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: auto;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
    img {
      width: 100%;
      height: 230px;
    }
  }
  .doc {
    padding: 0 0 50px 30px;
    .title {
      border-left: 4px solid #ee020d;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      font-size: 22px;
      margin: 40px 0;
    }
    .relative {
      position: relative;
      .more {
        position: absolute;
        right: 60px;
        top: 0px;
        color: #999;
        font-size: 16px;
        cursor: pointer;
      }
      .more:hover {
        color: #aa001b;
      }
      .clearfix {
        display: flow-root;
        .showAdiv{
          display: block;
        }
        .hideAdiv{
          display: none;
        }
        .video {
          width: 40%;
          margin-left: 6%;
          margin-bottom: 4%;
          float: left;
          text-align: center;
          font-size: 16px;
          position: relative;
          height: 295px;
          text-decoration: none;
          outline: none;
          color: #333;
          span {
            background: url(http://www.juyike.cn/Public/Home/images/mediaBg.png)
            no-repeat;
            width: 100%;
            position: absolute;
            z-index: 6;
            left: 0;
            top: 0;
            height: 100%;
            background-size: 100%;
          }
          img {
            max-width: 472px;
            margin-bottom: 10px;
            height: 89%;
            width: 100%;
          }
        }
        .video:hover {
          color: #aa001b;
        }
      }
    }
    .ygfcscroll{
      position:relative;
      padding:40px 0px 20px;
      color:#999;
      margin-left:-30px;
    }
    .ygfcscroll-box{width:940px;margin:0 auto 50px;overflow:hidden;}
    .ygfcscroll-prev,.ygfcscroll-next{
      position:absolute;
      top:42%;
      width:32px;
      height:32px;
      background:url(../../assets/point02.png) no-repeat 0 0;
      opacity: .5
    }
    .ygfcscroll-prev{left:2%;background-position: 0 0;}
    .ygfcscroll-next{right:2%;background-position: -35px 0;}
    .ygfcscroll-prev:hover,.ygfcscroll-next:hover{opacity:9}
    .ygfcscroll-box ul{width:9999px;position:relative;}
    .ygfcscroll-box li{
      float:left;
      width:2.25%;
      border:1px solid #e5e5e5;
      margin-right:10px;
      position: relative;
      list-style-type: none;
    }
    .ygfcscroll-box li a{color:#666;}
    #ygfcscroll-btn{display:none;}
    .selectBox{ text-align: center;}
    // 聚说
    .ygfcscrolls{
      position:relative;
      padding:40px 0px 20px;
      color:#999;
      margin-left:-30px;
    }
    .ygfcscroll-boxs{width:940px;margin:0 auto 50px;overflow:hidden;}
    .ygfcscroll-prevs,.ygfcscroll-nexts{
      position:absolute;
      top:42%;
      width:32px;
      height:32px;
      background:url(../../assets/point02.png) no-repeat 0 0;
      opacity: .5
    }
    .ygfcscroll-prevs{left:2%;background-position: 0 0;}
    .ygfcscroll-nexts{right:2%;background-position: -35px 0;}
    .ygfcscroll-prevs:hover,.ygfcscroll-nexts:hover{opacity:9}
    .ygfcscroll-boxs ul{width:9999px;position:relative;}
    .ygfcscroll-boxs li{
      float:left;
      width:887px;
      border:1px solid #e5e5e5;
      /*margin-right:10px;*/
      margin: 0 17px;
      position: relative;
      list-style-type: none;
    }
    .ygfcscroll-boxs li a{color:#666;}
    #ygfcscroll-btns{display:none;}
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  /*.el-carousel__item:nth-child(2n) {*/
  /*  background-color: #99a9bf;*/
  /*}*/
  
  /*.el-carousel__item:nth-child(2n+1) {*/
  /*  background-color: #d3dce6;*/
  /*}*/
  .elSwiper{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  /*/deep/ .el-carousel__container{*/
  /*  min-height: 887px !important;*/
  /*}*/
  /deep/ .el-carousel__item{
    display: block !important;
  }
}
</style>
