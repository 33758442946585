<template>
  <div class="container">
    <div class="banner">
      <img
        :src="enterpricedata.titleimg.picture"
        v-if="enterpricedata.titleimg.picture"
        :alt="titleName"
      />
      <h2>{{ titleName }}</h2>
    </div>
    <div class="doc">
      <div class="state-p" v-html="enterpricedata.content.content">
      </div>
      <ol class="navbar">
        <li v-for="item in enterpricedata.childTitles" :key="item.id">
          <a :class="currentTitleId === item.id ? 'link' : ''"  @click="titleClick(item)">{{item.titleName}}</a>
        </li>
      </ol>
      <div class="recruit">
        <el-table
          :data="tableData"
          style="width: 100%"
          v-if="currentTitleId === '1430461404405563392'"
          :header-cell-style="{ background: red }"
          empty-text="暂无数据"
        >
          <el-table-column align="center" prop="companyName" label="公司"> </el-table-column>
          <el-table-column align="center" prop="position" label="职务"> </el-table-column>
          <el-table-column align="center" prop="dept" label="部门"> </el-table-column>
          <el-table-column align="center" prop="hiring" label="招聘人数"> </el-table-column>
          <el-table-column align="center" prop="wrokplace" label="工作地点"> </el-table-column>
          <el-table-column align="center" prop="releaseTime" label="发布日期" :formatter="dateFormat"> </el-table-column>
        </el-table>
        <p class="ypxz" v-if="currentTitleId === '1430461467479506944'">
          <img
            :src="data.titleimg[0].pictureUrl"
            alt="应聘须知"
          />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { Join_list, getPostList, getImgUrl, getNotesForApplying, dateFormat } from '@/api/index.js';
export default {
  data() {
    return {
      enterpricedata:{ // 页面详情数据
        childTitles: [],
        content: {},
        titleimg: {},
      },
      tableData: [], // 招聘岗位列表
      titleName: '',
      data: {
        titleimg: []
      }, // 应聘须知
      currentTitleId: '',// 标题点击的id
    };
  },
  created() {
    this.getData();
    
  },
  methods: {
     
    // 获取数据
    getData() {
      let menuList = JSON.parse(sessionStorage.getItem("menuList"));
      menuList.map(item => {
        if (item.children) {
          menuList = menuList.concat(item.children)
        }
      })
      let item = menuList.filter(item => item.path === this.$route.path);
      this.titleName = item[item.length-1].titleName;
      const data = {id:item[item.length-1].id};
      Join_list(data).then(res=> {
        this.enterpricedata = res.data
        this.enterpricedata.titleimg.picture = getImgUrl(res.data.titleimg.picture)
        this.titleClick(res.data.childTitles[0])

      })
      .catch(error=>{
          console.log(error)
      });
    },
    details(type) {
      this.$router.push({ path: "/details", query: { type: type } });
    },
    titleClick(item) {
      this.currentTitleId = item.id;
      if (item.id === '1430461404405563392') {
        getPostList().then(res=> {
          if (res.data) {
            this.tableData = res.data;
          }
        }).catch(error=>{
          console.log(error)
        });
      } else if (item.id === '1430461467479506944') {
        getNotesForApplying({id: item.id}).then(res=> {
          if (res.data) {
            this.data = res.data;
            this.data.titleimg[0].pictureUrl = getImgUrl(res.data.titleimg[0].pictureUrl)
          }
        }).catch(error=>{
          console.log(error)
        });
      }
    },
    dateFormat(row) {
      return row.releaseTime && dateFormat(row.releaseTime).split(' ')[0];
    },
  },
};
</script>
<style lang="less">
.container {
  width: 78.5%;
  left: 12.5%;
  position: absolute;
  top: 0;
  min-width: 1050px;
  max-width: 1200px;
  box-shadow: 5px 0 5px #f0f0f0;
  font-family: "微软雅黑";
  .banner {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 230px;
    }
    h2 {
      font-weight: 500;
      color: #fff;
      opacity: 0.7;
      font-size: 42px;
      line-height: 42px;
      position: absolute;
      left: 25px;
      top: 40%;
    }
  }
  .doc {
    padding: 0 0 50px 30px;
    .state-p {
      margin: 30px 0 35px 5%;
      font-size: 18px;
      line-height: 30px;
    }
    .ypxz {
      width: 80%;
      margin: 0 auto;
      border: 1px solid #ccc;
      padding: 3% 0;
      img {
        max-width: 736px;
        margin: 0 10% 0 20%;
        width: 70%
      }
    }
    .navbar {
      background: url(http://www.juyike.cn/Public/Home/images/jyknavBg02.jpg) 0
        bottom no-repeat;
      width: 90%;
      margin: 30px auto;
      list-style-type: none;
      display: flex;
      li {
        width: 25%;
        height: 115px;
        float: left;
        text-align: center;
        cursor: pointer;
        a {
          color: #ee020d;
          font-size: 20px;
          height: 115px;
          line-height: 115px;
          display: inline-block;
          width: 100%;
          text-decoration: none;
          outline: none;
        }
        .link {
          background: url(http://www.juyike.cn/Public/Home/images/hover.jpg)
          no-repeat;
          color: #fff;
          background-size: 100% 115px;
        }
        a:hover {
          background: url(http://www.juyike.cn/Public/Home/images/hover.jpg)
            no-repeat;
          color: #fff;
          background-size: 100% 115px;
        }
      }
    }
    .recruit {
      .el-table__body-wrapper {
        width: 100% !important;
        margin: 0 0 !important;
      }
      table thead tr th {
        background: #e7e7e7;
        text-align: center;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px solid #e7e7e7;
        font-size: 16px;
        font-weight: normal;
        color: black;
      }
    }
  }
}
</style>
