<template>
    <div class="container">
        <div class="banner">
            <img
                    :src="imgUrl"
                    alt="关注聚益科"
            />
            <h2>关注聚益科</h2>
        </div>
        <div class="doc">
            <div class="detail relative">
                <h3 style="border:none;">{{ infoMap && infoMap.title ? infoMap.title : '暂无标题 ' }}</h3>
                <span class="time">{{ time }}</span>
                <router-link :to="{name:'follow'}" @click="goBackFnc()"><a class="back">返回</a></router-link>
                <div v-html="infoMap && infoMap.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { GaterInfoJYK, getImgUrl, dateFormat } from '@/api/index.js';
    export default {
        data() {
            return {
                infoMap: {},
                time: '',
                imgUrl: '',
            };
        },
        created(){
            this.getData()
        },
        methods: {
            dateFormat(time) {
                return dateFormat(time)
            },
            goBackFnc(){
                sessionStorage.removeItem("followId");
            },
            // 获取数据
            getData() {
                const data={ id: this.$route.params.id ? this.$route.params.id : sessionStorage.getItem("followId")};
                GaterInfoJYK(data).then(res=> {
                    if(res.success){
                        this.infoMap = res.data;
                        if(res.data.releaseTime && res.data.releaseTime != '') {
                            this.time = this.dateFormat(res.data.releaseTime).split(' ')[0]
                        }
                        if(res.data && res.data.headlineImg != ''){
                            this.imgUrl = getImgUrl(res.data.headlineImg);
                        }
                    }
                })
                .catch(error=>{
                    console.log(error)
                });
            },
        },
    };
</script>
<style lang="less">
    .container {
        width: 78.5%;
        left: 12.5%;
        position: absolute;
        top: 0;
        min-width: 1050px;
        max-width: 1200px;
        box-shadow: 5px 0 5px #f0f0f0;
        font-family: "微软雅黑";
        .banner {
            width: 100%;
            position: relative;
            img {
                width: 100%;
                height: 230px;
            }
            h2 {
                font-weight: 500;
                color: #fff;
                opacity: 0.7;
                font-size: 42px;
                line-height: 42px;
                position: absolute;
                left: 25px;
                top: 40%;
            }
        }
        .doc {
            .detail {
                margin: 45px auto;
                width: 90%;
                font-size: 16px;
                h3{text-align: center;font-size:20px;font-weight: normal; }
                .back{
                    width:106px;
                    height:35px;
                    background:#eee;
                    border:1px solid #dcdcdc;
                    text-align: center;
                    line-height:35px;
                    position: absolute;
                    top:0;
                    right:0;
                    display: block;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .time{margin:20px 0 40px; text-align: center; width:100%;
                    display: inline-block;}
            }
            .relative {
                position: relative;
            }
        }
    }
</style>
